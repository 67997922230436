import { Component, OnInit} from '@angular/core';
import {Message, SelectItem, MessageService} from 'primeng/api';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {SitedetailsService} from '../../services/sitedetails.service';
import {SiteriskdetailsService} from '../../services/siteriskdetails.service';
import {CreateeSRA} from '../../models/createeSRA-details';
import {UserIdService} from '../../services/user-id.service.service';
import {UtilService} from '../../services/util.service';
import {GetPartData} from '../../models/part-details';
import {CDSLookUpResponse} from '../../models/cdsIdLookUp-response';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';

@Component({
	selector: 'createesra',
	templateUrl: './createesra.component.html',
	styleUrls: ['./createesra.component.scss'],
	providers: [MessageService]
})
export class CreateesraComponent implements OnInit {
	message: Message[] = [];
	colors: SelectItem[];
	siteDetails: CreateeSRA;
	siteDetailsForCreate: CreateeSRA;
	primarySite: CreateeSRA;

	userID;

	siteCode: string;
	psaNumber: string;
	color: string = '';
	textColor: string;
	overallTextColor: string;
	NA: string = 'Not Available';
	greenOrBrown: string = '';
	noCommodityMsg: string;
	errorMsg: string;
	partStatus: string;
	companyName: string;

	isSuccess: boolean = false;
	isError: boolean;
	agree: boolean = false;
	isGSDB: boolean = false;
	noCommodity: boolean = false;

	partDetails: GetPartData[];
	private cdsLookUpResponse: CDSLookUpResponse[];
	filteredCDSIDs: any[];
	leadSTAId = false;
	NO_MATCH_FOUND: string = "No Match Found, Please try again";
	NOTIFY_USERS ="Enter the CDSID of any additional personnel (separated by a semicolon) that you would like to be included on system-generated emails. By default, all emails automatically include the assigned Buyer, Lead STA, and the Lead STA’s immediate supervisor so these should not be entered here.";

	constructor(private router: Router, private siteService: SitedetailsService, private createeSRARequest: SiteriskdetailsService,
				private http: HttpClient,private aRouter: ActivatedRoute, private userIdService: UserIdService, private messageService: MessageService, private utilService: UtilService) {

		this.colors = [
			{label: '---Select Color---', value: '---Select Color---'},
			{label: 'GREEN', value: 'GREEN'},
			{label: 'YELLOW', value: 'YELLOW'},
			{label: 'RED', value: 'RED'},
		];
	}
	ngOnInit() {
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.userIdService.userID.subscribe((value: string) => {
				this.userID = value.toUpperCase();
			});
			this.siteCode = paramMap.get('siteCode');
			this.psaNumber = paramMap.get('psaNumber');
		});
		this.getDataForCreate();
		this.getCompanyName();
	}
	keyPressAlphaNumeric(event) {
		const validation = String.fromCharCode(event.keyCode);
		if (/[a-zA-Z0-9; ]/.test(validation)) {
			this.errorMsg = '';
			return true;
		} else {
			event.preventDefault();
			this.errorMsg = 'Allows only AlphaNumeric Values and Semicolon';
			return false;
		}
	}
	createSRA() {
	//NOT USED
		/*if (this.siteDetails.greenORbrown === null) {
			this.utilService.pushMsg('error', 'Error', 'Greenfield / Brownfield must be selected before sending to STA');
		}
		if(!this.agree){
			this.utilService.pushMsg('error', 'Error', 'PSA details must be verified before sending to STA');
		}
		if ((this.siteDetails.notifyUsersFlag) && (this.siteDetails.notifyAdditionalUsers == null || this.siteDetails.notifyAdditionalUsers === 'undefined' ||
			this.siteDetails.notifyAdditionalUsers == '')) {
			this.utilService.pushMsg('error', 'Error', 'Notify Additional User(s) are required');
		}
		if(this.siteDetails.staEng ==null || this.siteDetails.staEng == ''){
			this.utilService.pushMsg('error', 'Error', 'Lead STA (Site/Core) CDS ID is required');
		}*/
	//NOT USED END
		if ((this.siteDetails.notifyUsersFlag) && (this.siteDetails.notifyAdditionalUsers == null || this.siteDetails.notifyAdditionalUsers === 'undefined' ||
			this.siteDetails.notifyAdditionalUsers == '')) {
			this.errorMsg = 'Must be enter valid cds id(s).';
		} else {
			if (this.agree && this.siteDetails.greenORbrown != null) {
				this.isSuccess = true;
				this.siteDetailsForCreate = this.siteDetails;
				this.siteDetailsForCreate.isJVBuyer = sessionStorage.getItem("roleName");
				this.createeSRARequest.createSRA(this.siteDetailsForCreate,this.companyName).subscribe(data => {
						if (data != null) {
							this.utilService.pushMsg('success', 'Success', 'eSRA request has been successfully sent to STA for review.');
							this.router.navigate(['home']);
						}
					}
					, error => {
						console.log('error.status code in createSRA Method::'+error.status);
						if (error.status === 404) {
							this.utilService.pushMsg('error', 'Error', 'Enter Valid Lead STA (Site/Core) CDS ID');
							error.status.clear;
						} else if (error.status === 406) {
							this.utilService.pushMsg('error', 'Error', 'Enter Valid CDSID(s) for Notified Additional Users');
							error.status.clear;
						} else {
							this.utilService.pushMsg('error', 'Error', 'Unable to create request , Please try again later.');
						}
					});
			} else {
				this.isError = false;
				this.isSuccess = true;
				this.greenOrBrown = 'Greenfield / Brownfield must be selected before sending to STA';
			}
		}
	}
	onLoadBGColor(colorCode) {
		let diffColor;
		if (colorCode === 'G' || colorCode === 'GREEN') {
			diffColor = 'green';
			this.textColor = 'white';
		} else if (colorCode === 'Y' || colorCode === 'YELLOW') {
			this.textColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'R' || colorCode === 'RED') {
			this.textColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'B' || colorCode === 'BLUE') {
			this.textColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}

	getDataForCreate() {
		this.siteService.getSIMSiteDetails(this.siteCode).subscribe(data => {
			if (data != null) {
				this.siteDetails = data;
				data.buyer = this.userID;
				if (data.qualtiyRiskColor === 'B') {
					this.siteDetails.qualtiyRiskColor = 'BLUE';
				}
				if (data.qualtiyRiskColor === 'R') {
					this.siteDetails.qualtiyRiskColor = 'RED';
				}
				if (data.qualtiyRiskColor === 'Y') {
					this.siteDetails.qualtiyRiskColor = 'YELLOW';
				}
				if (data.qualtiyRiskColor === 'G') {
					this.siteDetails.qualtiyRiskColor = 'GREEN';
				}

				if (data.q1Score == null) {
					this.siteDetails.q1Score = this.NA;
				} else {
					this.siteDetails.q1Score = data.q1Score + ' / 100';
				}
				if (data.q1Status == null) {
					this.siteDetails.q1Status = this.NA;
				}
				if (data.ppm == null) {
					this.siteDetails.ppm = this.NA;
				} else {
					this.siteDetails.ppm = data.ppm + ' / 10';
				}
				if (data.openwrntySpikes == null) {
					this.siteDetails.openwrntySpikes = this.NA;
				} else {
					this.siteDetails.openwrntySpikes = data.openwrntySpikes + ' / 5';
				}
				if (data.stopshipments == null) {
					this.siteDetails.stopshipments = this.NA;
				} else {
					this.siteDetails.stopshipments = data.stopshipments + ' / 20';
				}
				if (data.openFSA == null) {
					this.siteDetails.openFSA = this.NA;
				} else {
					this.siteDetails.openFSA = data.openFSA + ' / 15';
				}
				if (data.dlySixMonthsAvg == null) {
					this.siteDetails.dlySixMonthsAvg = this.NA;
				} else {
					this.siteDetails.dlySixMonthsAvg = data.dlySixMonthsAvg + ' / 10';
				}
				if (data.priorLaunchPrf == null) {
					this.siteDetails.priorLaunchPrf = this.NA;
				} else {
					this.siteDetails.priorLaunchPrf = data.priorLaunchPrf + ' / 5';
				}
				if (data.qltySysQ1MSA == null) {
					this.siteDetails.qltySysQ1MSA = this.NA;
				} else {
					this.siteDetails.qltySysQ1MSA = data.qltySysQ1MSA + ' / 15';
				}
				this.siteDetails.greenORbrown = null;
				if (data.virtualSite === 'Virtual') {
					this.siteService.getSIMSiteDetails(data.primarySite).subscribe(data => {
						this.siteDetails.staEng = data.staEng;
					});
				}
				this.retrievePSADetails();
			} else {
				this.isGSDB = true;
				this.siteService.getGSDBSiteDetails(this.siteCode).subscribe(gsdbsite => {
					this.retrievePSADetails();
					this.siteDetails = gsdbsite;
					this.siteDetails.siteCode = gsdbsite.gsdbSite;
					this.siteDetails.buyer = this.userID;
					if (gsdbsite.supplierName === '~!' || gsdbsite.supplierName === '~!@') {
						this.siteDetails.supplierName = null;
					}
					if (gsdbsite.city === '~!' || gsdbsite.city === '~!@') {
						this.siteDetails.city = null;
					}
					if (gsdbsite.state === '~!' || gsdbsite.state === '~!@') {
						this.siteDetails.state = null;
					}
					if (gsdbsite.country === '~!' || gsdbsite.country === '~!@') {
						this.siteDetails.country = null;
					}
				});
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com ' +
				'with a screenshot of this error message.');
		});
	}

	private retrievePSADetails() {
		this.siteService.getPSADetails(this.psaNumber).subscribe(data => {
			if (data !== null) {
				this.siteDetails.psaNumber = data.psaNumber;
				this.siteDetails.modelYear = data.modelYear;
				this.siteDetails.commodityCode = data.commodityCode;
				this.siteDetails.program = data.program;
				this.siteDetails.keyCommodity = data.keyCommodity;
				this.siteDetails.esfCommodity = data.esfCommodity;
				this.siteDetails.pmt = data.pmt;
				this.siteDetails.vhm = data.vhm;
				if (data.step2PlanDate != '') {
					this.siteDetails.step2PlanDate = this.convertDate(data.step2PlanDate);
				} else {
					this.siteDetails.step2PlanDate = data.step2PlanDate;
				}
				if (data.step2ForecastDate != '') {
					this.siteDetails.step2ForecastDate = this.convertDate(data.step2ForecastDate);
				} else {
					this.siteDetails.step2ForecastDate = data.step2ForecastDate;
				}
				if (data.commodityCode == null || data.commodityCode === '') {
					this.noCommodity = true;
					this.noCommodityMsg = 'Commodity is missing.  Please exit the system and update commodity information ' +
						'in SWT before creating an eSRA for this PSA.';
				}
				/*if (data.esfCommodity == null || data.esfCommodity === '') {
					this.siteDetails.staEng = null;
				}*/
			}
			this.viewPartDetails(this.psaNumber);

		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SWT, Please contact ESRASUPP@ford.com ' +
				'with a screenshot of this error message.');
		});
	}

	convertDate(longDate) {
		const radix = 10;
		const date = new Date(parseInt(longDate, radix));
		return this.generateUTCDate(date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear());
	}

	private generateUTCDate(day, month, year) {
		const actualMonth = Number(month) + 1;
		let monthFormat;
		if (actualMonth != null) {
			if (actualMonth.toString() === '01' || actualMonth.toString() === '1') {
				monthFormat = 'Jan';
			} else if (actualMonth.toString() === '02' || actualMonth.toString() === '2') {
				monthFormat = 'Feb';
			} else if (actualMonth.toString() === '03' || actualMonth.toString() === '3') {
				monthFormat = 'Mar';
			} else if (actualMonth.toString() === '04' || actualMonth.toString() === '4') {
				monthFormat = 'Apr';
			} else if (actualMonth.toString() === '05' || actualMonth.toString() === '5') {
				monthFormat = 'May';
			} else if (actualMonth.toString() === '06' || actualMonth.toString() === '6') {
				monthFormat = 'Jun';
			} else if (actualMonth.toString() === '07' || actualMonth.toString() === '7') {
				monthFormat = 'Jul';
			} else if (actualMonth.toString() === '08' || actualMonth.toString() === '8') {
				monthFormat = 'Aug';
			} else if (actualMonth.toString() === '09' || actualMonth.toString() === '9') {
				monthFormat = 'Sep';
			} else if (actualMonth.toString() === '10') {
				monthFormat = 'Oct';
			} else if (actualMonth.toString() === '11') {
				monthFormat = 'Nov';
			} else if (actualMonth.toString() === '12') {
				monthFormat = 'Dec';
			}
		}
		return `${day.toString()}-${monthFormat.toString()}-${year.toString()}`;
	}

	viewPartDetails(psa: string) {
		this.siteService.getPartDetails(psa).subscribe(parts => {
			if(parts.status !== null){
				this.partStatus = parts.status;
			}else{
				this.partStatus == null;
				this.partDetails = new Array(parts.swtParts.length);
				for (let i = 0; i < parts.swtParts.length; i++) {
					this.partDetails[i] = new GetPartData(parts.swtParts[i]);
				}
			}
		});
	}
	getCompanyName() {
		/*get Company Name*/
		this.userIdService.companyName.subscribe((value: string) => {
			this.companyName = value;
			sessionStorage.setItem("companyName", this.companyName);
			console.log("Employee Type from Session Storage::" + sessionStorage.getItem("companyName"));
		});
	}

	noMatchFoundValid(value: string, checkValid) {
		//lead STA
		if (value == 'leadSTA') {
			this.leadSTAId = checkValid;
		}
	}
	getCDSId(value: string) {
		if (value == 'leadSTA') {
			this.siteDetails.staEng = this.siteDetails.staEng.split('-')[0].trim();
		}
	}

	searchKeywordForCDSId(event, name: string) {
		let filtered: any[] = [];
		this.cdsLookUpResponse = [];
		const userCDSId = event.query;
		if (userCDSId.length > 2) {
			this.noMatchFoundValid(name, false);
			this.siteService.getCDSIdDetails(userCDSId).subscribe(cdsIDs => {
					this.cdsLookUpResponse = cdsIDs;
					if (this.cdsLookUpResponse !== null) {
						for (let i = 0; i < this.cdsLookUpResponse.length; i++) {
							let cdsId = this.cdsLookUpResponse[i].userId+ '-' +this.cdsLookUpResponse[i].fordDisplayName+ '-' +this.cdsLookUpResponse[i].fordDesignation;
							filtered.push(cdsId);
						}
						this.filteredCDSIDs = filtered;
						filtered = [];
					} else {
						this.noMatchFoundValid(name, true);
						this.filteredCDSIDs = [];
					}
				}, error => {
					console.log(error.status);
					this.noMatchFoundValid(name, true);
				});
		} else {
			this.noMatchFoundValid(name, false);
			this.filteredCDSIDs = [];
			this.cdsLookUpResponse = [];
		}
	}

	openLink() {
		const link:string="https://azureford.sharepoint.com/:x:/r/sites/ProdPurchSTA/_layouts/15/Doc.aspx?sourcedoc=%7B62A828ED-1E19-42F1-9004-0925CD5056E0%7D&action=edit&source=https%3A%2F%2Fazureford.sharepoint.com%2Fsites%2FProdPurchSTA%2FSTA%2520Tech%2520Refresh%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FProdPurchSTA%252FSTA%2520Tech%2520Refresh%252FGCP%2520Migration%2520%2528PCF%2520to%2520GCP%2520Migration%2529%252FTech%2520M%2520Documents%252FSIM%252FTesting%26FolderCTID%3D0x01200070501A76877F2F4B832F42F5386B850A%26View%3D%257B7816B3B5%252D4D10%252D42E5%252D8F0B%252D07E91B0CEE59%257D%26InitialTabId%3DRibbon%252ERead%26VisibilityContext%3DWSSTabPersistence.xlsx";
		window.open(link,'_blank');
		// this.router.navigate([], { url:link, target: '_blank' });
	}
	public downloadExcelFile() {
		//const link='https://azureford.sharepoint.com/:x:/r/sites/ProdPurchSTA/_layouts/15/Doc.aspx?sourcedoc=%7B62A828ED-1E19-42F1-9004-0925CD5056E0%7D&action=edit&source=https%3A%2F%2Fazureford.sharepoint.com%2Fsites%2FProdPurchSTA%2FSTA%2520Tech%2520Refresh%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FProdPurchSTA%252FSTA%2520Tech%2520Refresh%252FGCP%2520Migration%2520%2528PCF%2520to%2520GCP%2520Migration%2529%252FTech%2520M%2520Documents%252FSIM%252FTesting%26FolderCTID%3D0x01200070501A76877F2F4B832F42F5386B850A%26View%3D%257B7816B3B5%252D4D10%252D42E5%252D8F0B%252D07E91B0CEE59%257D%26InitialTabId%3DRibbon%252ERead%26VisibilityContext%3DWSSTabPersistence.xls';
		const link='C:\\Users\\RBHASKA8\\Desktop\\AcctStatement.pdf';
		const blob=new Blob([link],{type:'application/octet-stream'});
		saveAs(blob, "test.xlsx");

		// this.http.get("https://azureford.sharepoint.com/:x:/r/sites/ProdPurchSTA/_layouts/15/Doc.aspx?sourcedoc=%7B62A828ED-1E19-42F1-9004-0925CD5056E0%7D&action=edit&source=https%3A%2F%2Fazureford.sharepoint.com%2Fsites%2FProdPurchSTA%2FSTA%2520Tech%2520Refresh%2FForms%2FAllItems.aspx%3FRootFolder%3D%252Fsites%252FProdPurchSTA%252FSTA%2520Tech%2520Refresh%252FGCP%2520Migration%2520%2528PCF%2520to%2520GCP%2520Migration%2529%252FTech%2520M%2520Documents%252FSIM%252FTesting%26FolderCTID%3D0x01200070501A76877F2F4B832F42F5386B850A%26View%3D%257B7816B3B5%252D4D10%252D42E5%252D8F0B%252D07E91B0CEE59%257D%26InitialTabId%3DRibbon%252ERead%26VisibilityContext%3DWSSTabPersistence.xlsx"
		// 	, {responseType: 'blob'})
		// 	.subscribe(blob => {
		// 		saveAs(blob, "test.xlsx");
		// 	});
	}

}

