import { Component, OnInit} from '@angular/core';
import {SiteriskdetailsService} from '../../services/siteriskdetails.service';
import {SitedetailsService} from '../../services/sitedetails.service';
import {UpdateeSRA} from '../../models/updateeSRA-details';
import {UtilService} from '../../services/util.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import {UserIdService} from '../../services/user-id.service.service';
import {ApsService} from '../../aps.service';
import { ConfirmationService } from 'primeng/api';
import {CreateeSRA} from '../../models/createeSRA-details';
import {GetPartData} from '../../models/part-details';
import {CDSLookUpResponse} from '../../models/cdsIdLookUp-response';
import {HttpClient} from '@angular/common/http';

@Component({
	selector: 'updateesra',
	templateUrl: './updateesra.component.html',
	styleUrls: ['./updateesra.component.scss'],
	providers: [MessageService]
})
export class UpdateesraComponent implements OnInit {

	siteRiskDetails: UpdateeSRA;
	siteDetails: CreateeSRA;

	capProRiskColors: SelectItem[];
	colors: SelectItem[];
	iLVSHealthColors: SelectItem[];
	iLVSHealthColors1: SelectItem[];
	q1StaQ1MsaColors: SelectItem[];
	message: Message[] = [];

	userID: string
	data: String;
	siteCode: string;
	psaNumber: string;
	SELECT_COLOR: string = '---Select Color---';
	EMPTY: string = "";
	NA: string = 'Not Available';
	role: string = '';
	dottedLine: string = '----------------------------------';
	jvUsersMsg: string = this.EMPTY;
	maxChars = 500;
	isRadioSelected: boolean = true;
	isSuccess: boolean = false;
	staVerified: boolean = false;
	iseSRAComplete: boolean = false;
	isMandatory: boolean = false;
	isBuyer: boolean = false;
	isSTA: boolean = false;
	isTextBoxHide: boolean = false;
	isTempDate: boolean = false;
	display: boolean;

	overallTextColor: string;
	textColor: string;
	partStatus: string;
	partDetails: GetPartData[];

	sharedCapacity: string = this.EMPTY;
	localLabor: string = this.EMPTY;
	opePattern: string = this.EMPTY;
	manfEqpRedi: string = this.EMPTY;
	color: string = this.EMPTY;
	finalColor: string = this.EMPTY;

	techCapability: string = this.EMPTY;
	siteLdrCpty: string = this.EMPTY;
	finRisk: string = this.EMPTY;
	ownership: string = this.EMPTY;
	localToolCpty: string = this.EMPTY;
	mulLaunch: string = this.EMPTY;
	ilvsExp: string = this.EMPTY;
	subtierMgmt: string = this.EMPTY;
	ll4Site: string = this.EMPTY;
	heathChartComp: string = this.EMPTY;
	supColor: string = this.EMPTY;
	overallSupRiskColor: string = this.EMPTY;

	proColor: string = this.EMPTY;
	setColorToParent: string = this.EMPTY;
	dt2Mpa: string = this.EMPTY;
	newDsnTech: string = this.EMPTY;
	gtcCompl: string = this.EMPTY;
	plannesPSW: string = this.EMPTY

	q1Status: string = this.EMPTY;
	ppm: string = this.EMPTY;
	warranty: string = this.EMPTY;
	stopship: string = this.EMPTY;
	fsa: string = this.EMPTY;
	delivery: string = this.EMPTY;
	priorlaunch: string = this.EMPTY;
	q1msa: string = this.EMPTY;

	qltyColor: string = this.EMPTY;
	overallQltyRiskColor: string = this.EMPTY;

	staAligned: string = '';
	qltyComments: string = '';
	qltyMitPlan: string = '';
	capComments: string = '';
	capMitPlan: string = '';
	supComments: string = '';
	supMitPlan: string = '';
	proComments: string = '';
	proMitPlan: string = '';

	dbRadioValue: string = '';
	confMessage: string = '';
	date: Date;
	RED_STATUS: string = "Mitigation Plan is required for all fields in RED Status";
	Y_B_STATUS: string = "Comments are required for all fields in YELLOW / BLUE Status";
	Y_STATUS: string = "Comments are required for all fields in YELLOW Status";
	STA_RED_STATUS: string = "Mitigation Plan is required for all STA fields in RED Status";
	STA_Y_B_STATUS: string = "Comments are required for all STA fields in YELLOW / BLUE  Status";
	CHAR_LENGTH: string = "Characters Remaining:";
	NO_MATCH_FOUND: string = "No Match Found, Please try again";
	NOTIFY_USERS = "Enter the CDSID of any additional personnel (separated by a semicolon) that you would like to be included on system-generated emails.By default, all emails automatically include the assigned Buyer, Lead STA, and the Lead STA’s immediate supervisor so these should not be entered here.";
	displayModal: boolean;
	displayModalReassign: boolean;
	reassign: boolean;
	reassignBuyer: string = null;
	siteReassign: boolean;
	newSiteLeadSTA: boolean=false;
	reassignSTAEng: string = null;
	confirmation:string="This Site does not have a Production STA assigned in SIM. Are you sure you want to proceed?";
	reassignSiteCode: string = null;
	isSimSite: string;
	keepClear:string;
	errorMsgBuyer: string;
    staEngFromDB:string;
	timeStampFromDB:string;
	constructor(private router: Router,
				private datePipe: DatePipe,
				private updateSRARequest: SiteriskdetailsService,
				private aRouter: ActivatedRoute,
				private siteService: SitedetailsService,
				private userIdService: UserIdService,
				private confirmationService: ConfirmationService,
				private apsService: ApsService, private messageService: MessageService, private utilService: UtilService,private http: HttpClient) {

		//Cap Risk
		this.capProRiskColors = [
			{label: "---Select Color---", value: "---Select Color---"},
			{label: "GREEN", value: "GREEN"},
			{label: "YELLOW", value: "YELLOW"},
			{label: "RED", value: "RED"},
			{label: "Not Available", value: "BLUE"},
		];

		this.colors = [
			{label: "---Select Color---", value: "---Select Color---"},
			{label: "GREEN", value: "GREEN"},
			{label: "YELLOW", value: "YELLOW"},
			{label: "RED", value: "RED"},
		];

		this.q1StaQ1MsaColors = [
			{label: "---Select Color---", value: "---Select Color---"},
			{label: "YELLOW", value: "YELLOW"},
			{label: "RED", value: "RED"},
		];

		// for tech Cap , using the same
		this.iLVSHealthColors = [
			{label: "---Select Color---", value: "---Select Color---"},
			{label: "GREEN", value: "GREEN"},
			{label: "RED", value: "RED"},
			{label: "Not Available", value: "BLUE"},
		];

		this.iLVSHealthColors1 = [
			{label: "---Select Color---", value: "---Select Color---"},
			{label: "GREEN", value: "GREEN"},
			{label: "RED", value: "RED"},
		];
	}
	ngOnInit() {
		this.date = new Date();
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.userIdService.userID.subscribe((value: string) => {
				this.userID = value.toUpperCase();
				console.log("UserID ::" + this.userID);
			});
			this.siteCode = paramMap.get('siteCode');
			this.psaNumber = paramMap.get('psaNumber');
			this.getRoles();
		});
	}
	spinnerFlag: boolean = true;
	private getRoles() {
		this.apsService.getRoles().subscribe(assignedRole => {
			this.spinnerFlag = true;
			this.role = assignedRole;
			console.log("Role ::" + this.role);
			if (this.role !== null && this.role !== '') {
				if (this.role == 'eSRA_Buyer' || this.role == 'eSRA_JV-Buyer' || this.role == 'eSRA_SuperUser') {
					this.isBuyer = true;
				}
				if (this.role == 'eSRA_STA-Engineer' || this.role == 'eSRA_JV-STA-Engineer' || this.role == 'SIM_JV-STA-Engineer' || this.role == 'STASiteEngineer'
					|| this.role == 'STASiteSupervisor' || this.role == 'eSRA_STA-Supervisor' || this.role === 'SIM_JV-STA-Supervisor' || this.role == 'eSRA_SuperUser') {
					this.isSTA = true;
				}
				this.getDataForUpdate();
			} else {
				this.router.navigate(['no-access']);
			}
		});
	}
isCurrentSiteInSIM:string;
	getDataForUpdate() {
		this.viewPartDetails(this.psaNumber);
		this.updateSRARequest.updateSiteDetails(this.siteCode, this.psaNumber,this.role).subscribe(data => {
			if(data === null){
				this.utilService.pushMsg('warn', 'Warning', 'Unable to update SRA, Please contact ESRASUPP@ford.com');
				this.spinnerFlag = false;
				this.router.navigate(['home']);
			} else{
			this.siteRiskDetails = data;
			this.staEngFromDB = data.staEng;
			this.dbRadioValue = data.greenORbrown
				this.isCurrentSiteInSIM = data.simOrGSDBSite;
			if (data.greenORbrown == 'NO') {
				this.isRadioSelected = true;
			} else {
				this.isRadioSelected = false;
			}
			if ((data.recordComplete == null || data.recordComplete == 'N') &&
				(((this.role == 'eSRA_Buyer' || this.role == 'eSRA_JV-Buyer') &&
						(this.userID == data.buyer || this.userID.toLowerCase() == data.buyer)) ||
					((this.role == 'eSRA_STA-Engineer' || this.role == 'eSRA_JV-STA-Engineer' || this.role == 'STASiteEngineer' ||
							this.role == 'SIM_JV-STA-Engineer' || this.role === 'eSRA_STA-Supervisor' || this.role === 'STASiteSupervisor'
							|| this.role === 'SIM_JV-STA-Supervisor') &&
						(this.userID == data.staEng || this.userID.toLowerCase() == data.staEng)))) {
				this.iseSRAComplete = false;
				this.jvUsersMsg = this.EMPTY;
			} else {
				this.iseSRAComplete = true;
				this.jvUsersMsg = "You are not authorized to access this page. Click on Home.";
			}
			if (data.recLastUpdatedUser != null) {
				this.siteRiskDetails.recLastUpdatedUser = this.userID;
			}

			if (data.qualtiyRiskColor == 'B')
				this.siteRiskDetails.qualtiyRiskColor = "BLUE"
			else if (data.qualtiyRiskColor == 'R')
				this.siteRiskDetails.qualtiyRiskColor = "RED"
			else if (data.qualtiyRiskColor == 'Y')
				this.siteRiskDetails.qualtiyRiskColor = "YELLOW"
			else if (data.qualtiyRiskColor == 'G')
				this.siteRiskDetails.qualtiyRiskColor = "GREEN"

			if (this.siteRiskDetails.greenORbrown != null) {
				if (((this.siteRiskDetails.greenORbrown == 'BROWNFIELD' || this.siteRiskDetails.greenORbrown == 'GREENFIELD' ||
						this.siteRiskDetails.greenORbrown == 'NO') && this.siteRiskDetails.simOrGSDBSite == 'N')
					|| ((this.siteRiskDetails.greenORbrown == 'BROWNFIELD' || this.siteRiskDetails.greenORbrown == 'GREENFIELD') &&
						this.siteRiskDetails.simOrGSDBSite == 'Y')) {//Global
					this.setColorNames();
				}
			}
			if (this.siteRiskDetails.healthChartColor == null)
				this.siteRiskDetails.healthChartColor = this.SELECT_COLOR;
			else
				this.heathChartComp = this.siteRiskDetails.healthChartColor;

			if (this.siteRiskDetails.ll4Site == null)
				this.siteRiskDetails.ll4Site = this.SELECT_COLOR;
			else
				this.ll4Site = this.siteRiskDetails.ll4Site;

			if (this.siteRiskDetails.sharedCpty == null)
				this.siteRiskDetails.sharedCpty = this.SELECT_COLOR;
			else
				this.sharedCapacity = this.siteRiskDetails.sharedCpty;

			if (this.siteRiskDetails.localLabor == null)
				this.siteRiskDetails.localLabor = this.SELECT_COLOR;
			else
				this.localLabor = this.siteRiskDetails.localLabor;

			if (this.siteRiskDetails.opePattern == null)
				this.siteRiskDetails.opePattern = this.SELECT_COLOR;
			else
				this.opePattern = this.siteRiskDetails.opePattern;

			if (this.siteRiskDetails.manfReadiness == null)
				this.siteRiskDetails.manfReadiness = this.SELECT_COLOR;
			else
				this.manfEqpRedi = this.siteRiskDetails.manfReadiness;

			if (this.siteRiskDetails.techCapability == null)
				this.siteRiskDetails.techCapability = this.SELECT_COLOR;
			else
				this.techCapability = this.siteRiskDetails.techCapability;

			if (this.siteRiskDetails.siteLdrCpty == null)
				this.siteRiskDetails.siteLdrCpty = this.SELECT_COLOR;
			else
				this.siteLdrCpty = this.siteRiskDetails.siteLdrCpty;

			if (this.siteRiskDetails.finRisk == null)
				this.siteRiskDetails.finRisk = this.SELECT_COLOR;
			else
				this.finRisk = this.siteRiskDetails.finRisk;

			if (this.siteRiskDetails.ownership == null)
				this.siteRiskDetails.ownership = this.SELECT_COLOR;
			else
				this.ownership = this.siteRiskDetails.ownership;

			if (this.siteRiskDetails.localToolCpty == null)
				this.siteRiskDetails.localToolCpty = this.SELECT_COLOR;
			else
				this.localToolCpty = this.siteRiskDetails.localToolCpty;

			if (this.siteRiskDetails.mulLaunch == null)
				this.siteRiskDetails.mulLaunch = this.SELECT_COLOR;
			else
				this.mulLaunch = this.siteRiskDetails.mulLaunch;

			if (this.siteRiskDetails.ilvsExp == null)
				this.siteRiskDetails.ilvsExp = this.SELECT_COLOR;
			else
				this.ilvsExp = this.siteRiskDetails.ilvsExp;

			if (this.siteRiskDetails.subtierMgmt == null)
				this.siteRiskDetails.subtierMgmt = this.SELECT_COLOR;
			else
				this.subtierMgmt = this.siteRiskDetails.subtierMgmt;

			if (this.siteRiskDetails.dt2Mpa == null)
				this.siteRiskDetails.dt2Mpa = this.SELECT_COLOR;
			else
				this.dt2Mpa = this.siteRiskDetails.dt2Mpa;

			if (this.siteRiskDetails.newDsnTech == null)
				this.siteRiskDetails.newDsnTech = this.SELECT_COLOR;
			else
				this.newDsnTech = this.siteRiskDetails.newDsnTech;

			if (this.siteRiskDetails.gtcCompl == null)
				this.siteRiskDetails.gtcCompl = this.SELECT_COLOR;
			else
				this.gtcCompl = this.siteRiskDetails.gtcCompl;

			if (this.siteRiskDetails.plannesPSW == null)
				this.siteRiskDetails.plannesPSW = this.SELECT_COLOR;
			else
				this.plannesPSW = this.siteRiskDetails.plannesPSW;

			if (data.ll6Verified == 'Y')
				this.staVerified = true;

			if (this.userID == data.buyer || this.userID == data.staEng ||
				this.userID.toLowerCase() == data.buyer || this.userID.toLowerCase() == data.staEng) {
				this.iseSRAComplete = false;
			} else {
				this.iseSRAComplete = true;
			}
			if (this.siteRiskDetails.currentDate !== null && this.siteRiskDetails.currentDate !== '') {
				this.isTempDate = true;
				this.timeStampFromDB=this.siteRiskDetails.currentDate;
			}
			if (this.siteRiskDetails.step2PlanDate != null && this.siteRiskDetails.step2PlanDate !='') {
				this.siteRiskDetails.step2PlanDate = this.convertDate(this.siteRiskDetails.step2PlanDate);
			}
			if (this.siteRiskDetails.step2ForecastDate != null && this.siteRiskDetails.step2ForecastDate !='') {
				this.siteRiskDetails.step2ForecastDate = this.convertDate(this.siteRiskDetails.step2ForecastDate);
			}
			this.spinnerFlag = false;
		}}
		);
	}
	checkBtn(value: String) {
		if (!value) {
			this.isTextBoxHide = true;
		} else {
			this.isTextBoxHide = false;
		}
	}
	private dateTest(value: string) {
		return value + ' GMT-4';
	}
	onSelectChangeDate() {
		this.isTempDate = false;
		if (this.staVerified) {
			this.siteRiskDetails.createdDateInEDT = this.datePipe.transform(Date.now(), 'dd-MMM-yyyy hh:mm a', 'EDT');
			this.siteRiskDetails.currentDate = this.datePipe.transform(Date.now(), 'dd-MMM-yyyy hh:mm a');
		} else {
			this.siteRiskDetails.currentDate = null;
			this.siteRiskDetails.createdDateInEDT = null;
		}
	}

	updateSRA(event: Event, SaveOrComplete) {
		if (this.dt2OwnerID || this.newDesignOwnerID || this.gtcOwnerID || this.plannedOwnerID ||
			this.technicalOwnerID || this.siteOwnerID || this.financialOwnerID || this.ownershipOwnerID || this.toolOwnerID ||
			this.multipleOwnerID || this.iLVSOwnerID || this.subTierOwnerID || this.level4OwnerID || this.healthOwnerID ||
			this.sharedOwnerID || this.localOwnerID || this.operatingOwnerID || this.manufactureOwnerID ||
			this.q1ScoreOwnerID || this.q1StatusOwnerID || this.ppmOwnerID || this.warrantyOwnerID ||
			this.ssOwnerID || this.fsaOwnerID || this.deliveryOwnerID || this.priorOwnerID || this.q1MSAOwnerID) {
			this.utilService.pushMsg('warn', 'Warning', 'You must enter the valid Owner CDSID for Mitigation Plan.');
		} else {
			if ((this.siteRiskDetails.notifyUsersFlag) && (this.siteRiskDetails.notifyAdditionalUsers == null ||
				this.siteRiskDetails.notifyAdditionalUsers == "")) {
				this.errorMsg = "Must be enter valid cds id(s).";
			} else {
				this.isSuccess = true;
				this.message = [];
				this.isLL6Verified(this.siteRiskDetails.currentDate)
				if ((this.isBuyer == true && (SaveOrComplete == 'Complete') && (this.siteRiskDetails.overallCapRisk != '' && this.siteRiskDetails.overallCapRisk != null) &&
						(this.siteRiskDetails.overallParentRisk != '' && this.siteRiskDetails.overallParentRisk != null) &&
						(this.siteRiskDetails.overallSupRisk != '' && this.siteRiskDetails.overallSupRisk != null) &&
						(this.siteRiskDetails.qualtiyRiskColor != '' && this.siteRiskDetails.qualtiyRiskColor != null) &&
						(this.siteRiskDetails.currentDate != '' && this.siteRiskDetails.currentDate != null)) ||
					(this.isSTA == true && (this.siteRiskDetails.sharedCpty != this.SELECT_COLOR) && (this.siteRiskDetails.localLabor != this.SELECT_COLOR) &&
						(this.siteRiskDetails.manfReadiness != this.SELECT_COLOR) && (this.siteRiskDetails.siteLdrCpty != this.SELECT_COLOR) &&
						(this.siteRiskDetails.localToolCpty != this.SELECT_COLOR) && (this.siteRiskDetails.mulLaunch != this.SELECT_COLOR) &&
						(this.siteRiskDetails.ilvsExp != this.SELECT_COLOR) && (this.siteRiskDetails.subtierMgmt != this.SELECT_COLOR) &&
						(this.siteRiskDetails.healthChartColor != this.SELECT_COLOR) && (this.siteRiskDetails.plannesPSW != this.SELECT_COLOR) &&
						(this.siteRiskDetails.techCapability != this.SELECT_COLOR))) {
					this.reset();
					this.mandatoryFieldsCheck();
					if (this.isMandatory) {
						this.utilService.pushMsg('warn', 'Warning', 'You must fill all the required fields to complete eSRA.');
					} else {
						if (this.isBuyer && SaveOrComplete == 'Complete') {
							if (event.defaultPrevented) return;
							event.preventDefault();
							this.confirmationService.confirm({
								key: 'confirm-Complete-eSRA',
								message: 'NOTE: By clicking “Complete eSRA”, you are finalizing your entry for this Site Code/PSA. You will no longer be able to edit this record.'
									+ '<br/><br>' + 'Are you sure you would like to complete?',
								reject: () => {
									this._updateNo();
								},
								accept: () => {
									this.updateYes(SaveOrComplete);
								},
							});
						} else {
							this.iseSRACompletedForUpdate(SaveOrComplete)
						}
					}
				} else {
					this.iseSRACompletedForUpdate(SaveOrComplete)
				}
			}
		}
	}

	private _updateNo() {
		console.log('eSRA Complete dropped');
	}
	updateYes(SaveOrComplete) {
		this.siteRiskDetails.userId = this.userID;
		this.siteRiskDetails.roleName = this.role;
		this.iseSRACompleteOrNot(SaveOrComplete);
		this.updateSRARequest.updateSRA(this.siteRiskDetails, this.role, SaveOrComplete).subscribe(data => {
				if (data != null) {
					if (data.recordComplete == 'N') {
						this.utilService.pushMsg('success', 'Success', 'eSRA has been successfully updated');
						this.router.navigate(['home']);
					} else {
						this.utilService.pushMsg('success', 'Success', 'eSRA has been successfully completed');
						this.router.navigate(['home']);
					}
				}
			}
			, error => {
				console.log('error.status code in updateSRA Method::'+error.status);
				if (error.status === 404) {
					this.utilService.pushMsg('error', 'Error', 'Enter Valid Lead STA (Site/Core) CDS ID.');
				} else if (error.status === 406) {
					this.utilService.pushMsg('error', 'Error', 'Enter Valid CDSID(s) for Notified Additional Users.');
				} else {
					//this.utilService.pushMsg('error', 'Error', 'Unable to update request , Please try again later.');
					this.utilService.pushMsg('success', 'Success', 'SRA request has been successfully completed.');
					this.router.navigate(['home']);
				}
			});
	}
	onCancel() {
		this.router.navigate(['home']);
	}
	errorMsg: string;

	keyPressAlphaNumeric(event) {
		let inp = String.fromCharCode(event.keyCode);
		if (/[a-zA-Z0-9; ]/.test(inp)) {
			this.errorMsg = "";
			return true;
		} else {
			event.preventDefault();
			this.errorMsg = "Allows only AlphaNumeric Values and Semicolon";
			return false;
		}
	}
	iseSRACompleteOrNot(SaveOrComplete) {
		if ((this.isBuyer) && (SaveOrComplete != '' && SaveOrComplete == 'Complete'))
			this.siteRiskDetails.recordComplete = "Y"
		else
			this.siteRiskDetails.recordComplete = "N"
	}

	isLL6Verified(currentDate) {
		if ((this.isSTA) && (currentDate != '' && currentDate != null)) {
			this.siteRiskDetails.ll6Verified = 'Y';
			this.staVerified = true;
		}
	}

	iseSRACompletedForUpdate(SaveOrComplete) {
		this.updateSRARequest.updateSiteDetails(this.siteCode, this.psaNumber,this.role).subscribe(data => {
			if ((this.isBuyer) || ((this.isSTA) && (data.recordComplete == null || data.recordComplete == 'N')))
				this.updateYes(SaveOrComplete);
			else
				this.utilService.pushMsg('warn', 'Warning', 'The buyer has completed the eSRA while you were trying to edit the eSRA. To modify the eSRA, please contact the admin.');
		})
	}

	changeTextColor(textColor) {
		if (textColor === this.NA || textColor === 'GREEN' || textColor === 'RED') {
			textColor = "black"
			//textColor = "white"
		} else {
			textColor = "black"
		}
		return textColor;
	}

	onLoadOverallTextColor(colorCode) {
		let diffColor;
		if (colorCode == "GREEN" || colorCode == "G") {
			this.overallTextColor = "white";
			diffColor = "green"
		} else if (colorCode == "YELLOW" || colorCode == "Y") {
			this.overallTextColor = "black";
			diffColor = "yellow"
		} else if (colorCode == "RED" || colorCode == "R") {
			this.overallTextColor = "white";
			diffColor = "red"
		} else if (colorCode == 'BLUE' || colorCode == "B") {
			this.overallTextColor = "white";
			diffColor = "blue";
		} else {
			diffColor = "white";
		}
		return diffColor;
	}

	changeDropdownBackgroundColor(color) {
		if (color == this.SELECT_COLOR) {
			color = 'white';
		} else if (color == this.NA) {
			color = 'blue';
		}
		return color;
	}
	onLoadBGColor(colorCode) {
		let bgColor;
		if (colorCode == "G") {
			this.textColor = "white";
			bgColor = "green"
		} else if (colorCode == 'Y') {
			bgColor = "yellow"
			this.textColor = 'black';
		} else if (colorCode == 'R') {
			this.textColor = "white";
			bgColor = "red"
		} else if (colorCode == 'B' || colorCode == 'Not Available') {
			this.textColor = "white";
			bgColor = "blue"
		} else {
			bgColor = "white"
		}
		return bgColor;
	}

	//Capacity Risk
	onSelectRisk(value: String, name: String) {
		this.staAlignedLL6Verified();
		if (value == "GREEN")
			this.color = "G";
		else if (value == "YELLOW")
			this.color = "Y";
		else if (value == "RED")
			this.color = "R";
		else if (value == this.NA)
			this.color = "B"
		else
			this.color = this.EMPTY;
		this.finalColor = this.color;

		if (name == "sharedCapacity")
			this.sharedCapacity = this.color;
		else if (name == "localLabor")
			this.localLabor = this.color;
		else if (name == "opePattern")
			this.opePattern = this.color;
		else if (name == "manfEqpRedi")
			this.manfEqpRedi = this.color;

		if (this.sharedCapacity != this.EMPTY && this.localLabor != this.EMPTY && this.opePattern != this.EMPTY && this.manfEqpRedi != this.EMPTY) {
			if (this.sharedCapacity == 'GREEN') this.sharedCapacity = 'G';
			if (this.sharedCapacity == 'RED') this.sharedCapacity = 'R';
			if (this.sharedCapacity == 'YELLOW') this.sharedCapacity = 'Y';
			if (this.sharedCapacity == this.NA) this.sharedCapacity = 'B';

			if (this.localLabor == 'GREEN') this.localLabor = 'G';
			if (this.localLabor == 'RED') this.localLabor = 'R';
			if (this.localLabor == 'YELLOW') this.localLabor = 'Y';
			if (this.localLabor == this.NA) this.localLabor = 'B';

			if (this.opePattern == 'GREEN') this.opePattern = 'G';
			if (this.opePattern == 'RED') this.opePattern = 'R';
			if (this.opePattern == 'YELLOW') this.opePattern = 'Y';
			if (this.opePattern == this.NA) this.opePattern = 'B';

			if (this.manfEqpRedi == 'GREEN') this.manfEqpRedi = 'G';
			if (this.manfEqpRedi == 'RED') this.manfEqpRedi = 'R';
			if (this.manfEqpRedi == 'YELLOW') this.manfEqpRedi = 'Y';
			if (this.manfEqpRedi == this.NA) this.manfEqpRedi = 'B';

			this.finalColor = this.sharedCapacity + this.localLabor + this.opePattern + this.manfEqpRedi;
			let capacityRedCount = 0, capacityYellowCount = 0, capacityGreenCount = 0, capacityBlueCount = 0;
			for (let i = 0; i < this.finalColor.length; i++) {
				if (this.finalColor[i] == 'R')
					capacityRedCount++;
				else if (this.finalColor[i] == 'Y')
					capacityYellowCount++;
				else if (this.finalColor[i] == 'B')
					capacityBlueCount++;
				else
					capacityGreenCount++;
			}

			if (capacityRedCount >= 1)
				this.siteRiskDetails.overallCapRisk = "RED";
			else if (capacityBlueCount >= 1)
				this.siteRiskDetails.overallCapRisk = "BLUE";
			else if (capacityYellowCount >= 1)
				this.siteRiskDetails.overallCapRisk = "YELLOW";
			else if (capacityGreenCount >= 1)
				this.siteRiskDetails.overallCapRisk = "GREEN";
			else
				this.siteRiskDetails.overallCapRisk = this.EMPTY;
		} else {
			this.finalColor = this.EMPTY;
			this.siteRiskDetails.overallCapRisk = this.EMPTY;
		}
	}

	//Supplier Risk
	overallSuppRisk(value: String, name: String) {
		this.staAlignedLL6Verified();
		if (value == "GREEN")
			this.supColor = "G";
		else if (value == "YELLOW")
			this.supColor = "Y";
		else if (value == "RED")
			this.supColor = "R";
		else if (value == this.NA)
			this.supColor = "B"
		else
			this.supColor = this.EMPTY;
		this.overallSupRiskColor = this.supColor;

		if (name == "techCapability")
			this.techCapability = this.supColor;
		else if (name == "siteLdrCpty")
			this.siteLdrCpty = this.supColor;
		else if (name == "finRisk")
			this.finRisk = this.supColor;
		else if (name == "ownership")
			this.ownership = this.supColor;
		else if (name == "localToolCpty")
			this.localToolCpty = this.supColor;
		else if (name == "ilvsExp")
			this.ilvsExp = this.supColor;
		else if (name == "mulLaunch")
			this.mulLaunch = this.supColor;
		else if (name == "subtierMgmt")
			this.subtierMgmt = this.supColor;
		else if (name == "ll4Site") {
			this.ll4Site = this.supColor;
		} else if (name == "heathChartComp") {
			this.heathChartComp = this.supColor;
		}
		if (this.techCapability != this.EMPTY && this.siteLdrCpty != this.EMPTY && this.finRisk != this.EMPTY &&
			this.ownership != this.EMPTY && this.localToolCpty != this.EMPTY && this.ilvsExp != this.EMPTY && this.mulLaunch != this.EMPTY &&
			this.subtierMgmt != this.EMPTY && this.heathChartComp != this.EMPTY && this.ll4Site != this.EMPTY) {
			if (this.techCapability == 'GREEN') this.techCapability = 'G';
			if (this.techCapability == 'RED') this.techCapability = 'R';
			if (this.techCapability == 'YELLOW') this.techCapability = 'Y';
			if (this.techCapability == this.NA) this.techCapability = 'B';

			if (this.siteLdrCpty == 'GREEN') this.siteLdrCpty = 'G';
			if (this.siteLdrCpty == 'RED') this.siteLdrCpty = 'R';
			if (this.siteLdrCpty == 'YELLOW') this.siteLdrCpty = 'Y';
			if (this.siteLdrCpty == this.NA) this.siteLdrCpty = 'B';

			if (this.ll4Site == 'GREEN') this.ll4Site = 'G';
			if (this.ll4Site == 'RED') this.ll4Site = 'R';
			if (this.ll4Site == 'YELLOW') this.ll4Site = 'Y';
			if (this.ll4Site == this.NA) this.ll4Site = 'B';

			if (this.finRisk == 'GREEN') this.finRisk = 'G';
			if (this.finRisk == 'RED') this.finRisk = 'R';
			if (this.finRisk == 'YELLOW') this.finRisk = 'Y';
			if (this.finRisk == this.NA) this.finRisk = 'B';

			if (this.ownership == 'GREEN') this.ownership = 'G';
			if (this.ownership == 'RED') this.ownership = 'R';
			if (this.ownership == 'YELLOW') this.ownership = 'Y';
			if (this.ownership == this.NA) this.ownership = 'B';

			if (this.localToolCpty == 'GREEN') this.localToolCpty = 'G';
			if (this.localToolCpty == 'RED') this.localToolCpty = 'R';
			if (this.localToolCpty == 'YELLOW') this.localToolCpty = 'Y';
			if (this.localToolCpty == this.NA) this.localToolCpty = 'B';

			if (this.ilvsExp == 'GREEN') this.ilvsExp = 'G';
			if (this.ilvsExp == 'RED') this.ilvsExp = 'R';
			if (this.ilvsExp == 'YELLOW') this.ilvsExp = 'Y';
			if (this.ilvsExp == this.NA) this.ilvsExp = 'B';

			if (this.mulLaunch == 'GREEN') this.mulLaunch = 'G';
			if (this.mulLaunch == 'RED') this.mulLaunch = 'R';
			if (this.mulLaunch == 'YELLOW') this.mulLaunch = 'Y';
			if (this.mulLaunch == this.NA) this.mulLaunch = 'B';

			if (this.subtierMgmt == 'GREEN') this.subtierMgmt = 'G';
			if (this.subtierMgmt == 'RED') this.subtierMgmt = 'R';
			if (this.subtierMgmt == 'YELLOW') this.subtierMgmt = 'Y';
			if (this.subtierMgmt == this.NA) this.subtierMgmt = 'B';

			if (this.heathChartComp == 'GREEN') this.heathChartComp = 'G';
			if (this.heathChartComp == 'RED') this.heathChartComp = 'R';
			if (this.heathChartComp == 'YELLOW') this.heathChartComp = 'Y';
			if (this.heathChartComp == this.NA) this.heathChartComp = 'B';

			this.overallSupRiskColor = this.techCapability + this.siteLdrCpty + this.finRisk + this.ownership +
				this.localToolCpty + this.mulLaunch + this.ilvsExp + this.subtierMgmt + this.ll4Site + this.heathChartComp;

			let supplierRedCount = 0, supplierYellowCount = 0, supGreenCount = 0, supplierBlueCount = 0;

			for (let i = 0; i < this.overallSupRiskColor.length; i++) {
				if (this.overallSupRiskColor[i] == 'R')
					supplierRedCount++;
				else if (this.overallSupRiskColor[i] == 'Y')
					supplierYellowCount++;
				else if (this.overallSupRiskColor[i] == 'B')
					supplierBlueCount++;
				else
					supGreenCount++;
			}
			if (supplierRedCount >= 1)
				this.siteRiskDetails.overallSupRisk = "RED";
			else if (supplierBlueCount >= 1)
				this.siteRiskDetails.overallSupRisk = "BLUE";
			else if (supplierYellowCount >= 1)
				this.siteRiskDetails.overallSupRisk = "YELLOW";
			else if (supGreenCount >= 1)
				this.siteRiskDetails.overallSupRisk = "GREEN";
			else
				this.siteRiskDetails.overallSupRisk = this.EMPTY;
		} else {
			this.overallSupRiskColor = this.EMPTY;
			this.siteRiskDetails.overallSupRisk = this.EMPTY;
		}
	}

	staAlignedLL6Verified() {
		if (this.isSTA) {
			this.staVerified = false;
			this.staAligned = '';
			this.siteRiskDetails.staAligned = '';
			this.siteRiskDetails.currentDate = '';
			this.siteRiskDetails.ll6Verified = 'N';
		}
	}

	selectedRadio(value: string) {
		if (this.siteRiskDetails.simOrGSDBSite == 'Y' && ((value == 'NO' && this.dbRadioValue == 'GREENFIELD') || (value == 'NO' && this.dbRadioValue == 'BROWNFIELD') ||
			(value == 'GREENFIELD' && this.dbRadioValue == 'NO') || (value == 'BROWNFIELD' && this.dbRadioValue == 'NO'))) {
			this.confMessage = 'Modifying the selection from ' + this.dbRadioValue + ' to ' + value + ' will remove the existing values selected in the Quality Risk Section.'
				+ '<br/><br>' + 'Are you sure you want to proceed?';
		} else if ((value == 'GREENFIELD' && this.dbRadioValue == 'BROWNFIELD') || (value == 'BROWNFIELD' && this.dbRadioValue == 'GREENFIELD') ||
			(value == 'NO' && this.dbRadioValue == 'BROWNFIELD') || (value == 'NO' && this.dbRadioValue == 'GREENFIELD') ||
			(value == 'BROWNFIELD' && this.dbRadioValue == 'NO') || (value == 'GREENFIELD' && this.dbRadioValue == 'NO')) {
			this.confMessage = 'Are you sure you want to modify the selection from ' + this.dbRadioValue + ' to ' + value + '?';
		}

		this.confirmationService.confirm({
			key: 'radioSelection',
			message: this.confMessage,
			reject: () => {
				this._noRadioChange();
			},
			accept: () => {
				this._changeRadioValue(value);
			},
		});
	}

	private _noRadioChange() {
		this.siteRiskDetails.greenORbrown = this.dbRadioValue;
		console.log('Radio button change dropped');
	}

	private _changeRadioValue(selectedRadio: string) {
		console.log('Radio value changed ');
		this.staAlignedLL6Verified()
		this.dbRadioValue = selectedRadio;

		if (this.siteRiskDetails.simOrGSDBSite == 'Y' && selectedRadio == 'NO') {
			this.q1Status = this.EMPTY;
			this.ppm = this.EMPTY;
			this.warranty = this.EMPTY;
			this.stopship = this.EMPTY;
			this.fsa = this.EMPTY;
			this.delivery = this.EMPTY;
			this.priorlaunch = this.EMPTY;
			this.q1msa = this.EMPTY;
			this.qltyColor = this.EMPTY;

			this.siteRiskDetails.qualtiyRiskColor = this.EMPTY;
			this.overallQltyRiskColor = this.EMPTY;
			this.siteService.getSIMSiteData(this.siteCode).subscribe(data => {
				this.isRadioSelected = true;
				if (data != null) {
					this.siteDetails = data;
					data.buyer = this.userID;
					if (data.qualtiyRiskColor == 'B')
						this.siteRiskDetails.qualtiyRiskColor = "BLUE"
					else if (data.qualtiyRiskColor == 'R')
						this.siteRiskDetails.qualtiyRiskColor = "RED"
					else if (data.qualtiyRiskColor == 'Y')
						this.siteRiskDetails.qualtiyRiskColor = "YELLOW"
					else if (data.qualtiyRiskColor == 'G')
						this.siteRiskDetails.qualtiyRiskColor = "GREEN"
					if (data.q1Score == null)
						this.siteRiskDetails.q1Score = this.NA;
					else
						this.siteRiskDetails.q1Score = data.q1Score + ' / 100';

					if (data.q1Status == null)
						this.siteRiskDetails.q1Status = this.NA;
					else
						this.siteRiskDetails.q1Status = data.q1Status;

					if (data.ppm == null)
						this.siteRiskDetails.ppm = this.NA;
					else
						this.siteRiskDetails.ppm = data.ppm + ' / 10';

					if (data.openwrntySpikes == null)
						this.siteRiskDetails.openwrntySpikes = this.NA;
					else
						this.siteRiskDetails.openwrntySpikes = data.openwrntySpikes + ' / 5';

					if (data.stopshipments == null)
						this.siteRiskDetails.stopshipments = this.NA;
					else
						this.siteRiskDetails.stopshipments = data.stopshipments + ' / 20';

					if (data.openFSA == null)
						this.siteRiskDetails.openFSA = this.NA;
					else
						this.siteRiskDetails.openFSA = data.openFSA + ' / 15';

					if (data.dlySixMonthsAvg == null)
						this.siteRiskDetails.dlySixMonthsAvg = this.NA;
					else
						this.siteRiskDetails.dlySixMonthsAvg = data.dlySixMonthsAvg + ' / 10';

					if (data.priorLaunchPrf == null)
						this.siteRiskDetails.priorLaunchPrf = this.NA;
					else
						this.siteRiskDetails.priorLaunchPrf = data.priorLaunchPrf + ' / 5';

					if (data.qltySysQ1MSA == null)
						this.siteRiskDetails.qltySysQ1MSA = this.NA;
					else
						this.siteRiskDetails.qltySysQ1MSA = data.qltySysQ1MSA + ' / 15';

					this.siteRiskDetails.q1ScoreColor = data.q1ScoreColor;
					this.siteRiskDetails.q1CodeColor = data.q1CodeColor;
					this.siteRiskDetails.ppmColor = data.ppmColor;
					this.siteRiskDetails.openwrntySpikesColor = data.openwrntySpikesColor;
					this.siteRiskDetails.openFSAColor = data.openFSAColor;
					this.siteRiskDetails.dlySixMonthsAvgColor = data.dlySixMonthsAvgColor;
					this.siteRiskDetails.priorLaunchPrfColor = data.priorLaunchPrfColor;
					this.siteRiskDetails.stopshipmentsColor = data.stopshipmentsColor;
					this.siteRiskDetails.qltySysQ1MSAColor = data.qltySysQ1MSAColor;
					this.resetQualityCommentsAndMitPlan();
				}
			});
		} else {
			if (this.isRadioSelected) {
				this.isRadioSelected = false;
				this.siteRiskDetails.qualtiyRiskColor = null;
				this.siteRiskDetails.q1ScoreColor = 'Not Applicable';
				this.siteRiskDetails.q1CodeColor = "---Select Color---";
				this.siteRiskDetails.ppmColor = "---Select Color---";
				this.siteRiskDetails.openwrntySpikesColor = "---Select Color---";
				this.siteRiskDetails.openFSAColor = "---Select Color---";
				this.siteRiskDetails.stopshipmentsColor = "---Select Color---";
				this.siteRiskDetails.dlySixMonthsAvgColor = "---Select Color---";
				this.siteRiskDetails.priorLaunchPrfColor = "---Select Color---";
				this.siteRiskDetails.qltySysQ1MSAColor = "---Select Color---";
			}
		}
	}
//Over all Quality Risk
	overallQualityRisk(value: String, name: String) {
		this.staAlignedLL6Verified();
		if (value == "GREEN")
			this.qltyColor = "G";
		else if (value == "YELLOW")
			this.qltyColor = "Y";
		else if (value == "RED")
			this.qltyColor = "R";
		else {
			this.qltyColor = this.EMPTY;
			this.overallQltyRiskColor = this.qltyColor;
		}

		if (name == "q1Status")
			this.q1Status = this.qltyColor;
		else if (name == "ppm")
			this.ppm = this.qltyColor;
		else if (name == "openwrntySpikes")
			this.warranty = this.qltyColor;
		else if (name == "stopshipments")
			this.stopship = this.qltyColor;
		else if (name == "openFSA")
			this.fsa = this.qltyColor;
		else if (name == "dlySixMonthsAvg")
			this.delivery = this.qltyColor;
		else if (name == "priorLaunchPrf")
			this.priorlaunch = this.qltyColor;
		else if (name == "qltySysQ1MSA")
			this.q1msa = this.qltyColor;

		if (this.q1Status != this.EMPTY && this.ppm != this.EMPTY && this.warranty != this.EMPTY && this.stopship != this.EMPTY && this.fsa != this.EMPTY &&
			this.delivery != this.EMPTY && this.priorlaunch != this.EMPTY && this.q1msa != this.EMPTY) {
			if (this.q1Status == 'GREEN') this.q1Status = 'G';
			if (this.q1Status == 'RED') this.q1Status = 'R';
			if (this.q1Status == 'YELLOW') this.q1Status = 'Y';

			if (this.ppm == 'GREEN') this.ppm = 'G';
			if (this.ppm == 'RED') this.ppm = 'R';
			if (this.ppm == 'YELLOW') this.ppm = 'Y';

			if (this.warranty == 'GREEN') this.warranty = 'G';
			if (this.warranty == 'RED') this.warranty = 'R';
			if (this.warranty == 'YELLOW') this.warranty = 'Y';

			if (this.stopship == 'GREEN') this.stopship = 'G';
			if (this.stopship == 'RED') this.stopship = 'R';
			if (this.stopship == 'YELLOW') this.stopship = 'Y';

			if (this.fsa == 'GREEN') this.fsa = 'G';
			if (this.fsa == 'RED') this.fsa = 'R';
			if (this.fsa == 'YELLOW') this.fsa = 'Y';

			if (this.delivery == 'GREEN') this.delivery = 'G';
			if (this.delivery == 'RED') this.delivery = 'R';
			if (this.delivery == 'YELLOW') this.delivery = 'Y';

			if (this.priorlaunch == 'GREEN') this.priorlaunch = 'G';
			if (this.priorlaunch == 'RED') this.priorlaunch = 'R';
			if (this.priorlaunch == 'YELLOW') this.priorlaunch = 'Y';

			if (this.q1msa == 'GREEN') this.q1msa = 'G';
			if (this.q1msa == 'RED') this.q1msa = 'R';
			if (this.q1msa == 'YELLOW') this.q1msa = 'Y';

			if (this.q1Status != this.SELECT_COLOR && this.ppm != this.SELECT_COLOR && this.warranty != this.SELECT_COLOR && this.stopship != this.SELECT_COLOR &&
				this.fsa != this.SELECT_COLOR && this.delivery != this.SELECT_COLOR && this.priorlaunch != this.SELECT_COLOR && this.q1msa != this.SELECT_COLOR) {
				this.overallQltyRiskColor = this.q1Status + this.ppm + this.warranty + this.stopship + this.fsa + this.delivery + this.priorlaunch + this.q1msa;
				var qualityRedCount = 0, qualityYellowCount = 0, qualityGreenCount = 0;
				for (let i = 0; i < this.overallQltyRiskColor.length; i++) {
					if (this.overallQltyRiskColor[i] == 'R')
						qualityRedCount++;
					else if (this.overallQltyRiskColor[i] == 'Y')
						qualityYellowCount++;
					else
						qualityGreenCount++;
				}
			}
			if (qualityRedCount >= 1)
				this.siteRiskDetails.qualtiyRiskColor = "RED";
			else if (qualityYellowCount >= 1)
				this.siteRiskDetails.qualtiyRiskColor = "YELLOW";
			else if (qualityGreenCount >= 1)
				this.siteRiskDetails.qualtiyRiskColor = "GREEN";
			else
				this.siteRiskDetails.qualtiyRiskColor = this.EMPTY;
		} else {
			this.overallQltyRiskColor = this.EMPTY;
			this.siteRiskDetails.qualtiyRiskColor = this.EMPTY;
		}
	}

	resetCommentsAndMitPlan(lable: String) {
		//Quality
		if (lable == 'q1Status') {
			this.siteRiskDetails.q1StatusComments = '';
			this.siteRiskDetails.q1StatusMitPlan = '';
			this.siteRiskDetails.q1StatusOwner = '';
			this.siteRiskDetails.q1StatusTgtDate = '';
		}
		if (lable == 'ppm') {
			this.siteRiskDetails.ppmComments = '';
			this.siteRiskDetails.ppmMitPlan = '';
			this.siteRiskDetails.ppmOwner = '';
			this.siteRiskDetails.ppmTgtDate = '';
		}
		if (lable == 'warrPerf') {
			this.siteRiskDetails.warrantyComments = '';
			this.siteRiskDetails.warrantyMitPlan = '';
			this.siteRiskDetails.warrantyOwner = '';
			this.siteRiskDetails.warrantyTgtDate = '';
		}

		if (lable == 'stopship') {
			this.siteRiskDetails.stopShipComments = '';
			this.siteRiskDetails.stopShipMitPlan = '';
			this.siteRiskDetails.stopShipOwner = '';
			this.siteRiskDetails.stopShipTgtDate = '';
		}
		if (lable == 'fsa') {
			this.siteRiskDetails.fsaComments = '';
			this.siteRiskDetails.fsaMitPlan = '';
			this.siteRiskDetails.fsaOwner = '';
			this.siteRiskDetails.fsaTgtDate = '';
		}
		if (lable == 'delSixMonth') {
			this.siteRiskDetails.deliveryComments = '';
			this.siteRiskDetails.deliveryMitPlan = '';
			this.siteRiskDetails.deliveryOwner = '';
			this.siteRiskDetails.deliveryTgtDate = '';
		}
		if (lable == 'priorLaunch') {
			this.siteRiskDetails.priorLaunchComments = '';
			this.siteRiskDetails.priorLaunchMitPlan = '';
			this.siteRiskDetails.priorLaunchOwner = '';
			this.siteRiskDetails.priorLaunchTgtDate = '';
		}
		if (lable == 'q1MSA') {
			this.siteRiskDetails.q1MSAComments = '';
			this.siteRiskDetails.q1MSAMitPlan = '';
			this.siteRiskDetails.q1MSAOwner = '';
			this.siteRiskDetails.q1MSATgtDate = '';
		}

		//cap
		if (lable == 'shared') {
			this.siteRiskDetails.sharedCapComments = '';
			this.siteRiskDetails.sharedCapMitPlan = '';
			this.siteRiskDetails.sharedCapOwner = '';
			this.siteRiskDetails.sharedCapTgtDate = '';
		}
		if (lable == 'local') {
			this.siteRiskDetails.localLaborComments = '';
			this.siteRiskDetails.localLaborMitPlan = '';
			this.siteRiskDetails.localLaborOwner = '';
			this.siteRiskDetails.localLaborTgtDate = '';
		}
		if (lable == 'ope') {
			this.siteRiskDetails.opePatComments = '';
			this.siteRiskDetails.opePatMitPlan = '';
			this.siteRiskDetails.opePatOwner = '';
			this.siteRiskDetails.opePatTgtDate = '';
		}
		if (lable == 'manf') {
			this.siteRiskDetails.manfCapComments = '';
			this.siteRiskDetails.manfCapMitPlan = '';
			this.siteRiskDetails.manfCapOwner = '';
			this.siteRiskDetails.manfCapTgtDate = '';
		}
		//sup
		if (lable == 'tech') {
			this.siteRiskDetails.techCapComments = '';
			this.siteRiskDetails.techMitPlan = '';
			this.siteRiskDetails.techOwner = '';
			this.siteRiskDetails.techTgtDate = '';
		}
		if (lable == 'siteleader') {
			this.siteRiskDetails.siteLeaderComments = '';
			this.siteRiskDetails.siteLeaderMitPlan = '';
			this.siteRiskDetails.siteLeaderOwner = '';
			this.siteRiskDetails.siteLeaderTgtDate = '';
		}
		if (lable == 'finrisk') {
			this.siteRiskDetails.finRiskComments = '';
			this.siteRiskDetails.finRiskMitPlan = '';
			this.siteRiskDetails.finRiskOwner = '';
			this.siteRiskDetails.finRiskTgtDate = '';
		}
		if (lable == 'ownership') {
			this.siteRiskDetails.ownershipComments = '';
			this.siteRiskDetails.ownershipMitPlan = '';
			this.siteRiskDetails.ownershipOwner = '';
			this.siteRiskDetails.ownershipTgtDate = '';
		}
		if (lable == 'toolCap') {
			this.siteRiskDetails.toolCapComments = '';
			this.siteRiskDetails.toolCapMitPlan = '';
			this.siteRiskDetails.toolCapOwner = '';
			this.siteRiskDetails.toolCapTgtDate = '';
		}
		if (lable == 'multiLaunch') {
			this.siteRiskDetails.multiLaunchComments = '';
			this.siteRiskDetails.multiLaunchMitPlan = '';
			this.siteRiskDetails.multiLaunchOwner = '';
			this.siteRiskDetails.multiLaunchTgtDate = '';
		}
		if (lable == 'ilvs') {
			this.siteRiskDetails.ilvsComments = '';
			this.siteRiskDetails.ilvsMitPlan = '';
			this.siteRiskDetails.ilvsOwner = '';
			this.siteRiskDetails.ilvsTgtDate = '';
		}
		if (lable == 'subtier') {
			this.siteRiskDetails.subtierComments = '';
			this.siteRiskDetails.subtierMitPlan = '';
			this.siteRiskDetails.subtierOwner = '';
			this.siteRiskDetails.subtierTgtDate = '';
		}
		if (lable == 'll4site') {
			this.siteRiskDetails.ll4SiteComments = '';
			this.siteRiskDetails.ll4SiteMitPlan = '';
			this.siteRiskDetails.ll4SiteOwner = '';
			this.siteRiskDetails.ll4SiteTgtDate = '';
		}
		if (lable == 'health') {
			this.siteRiskDetails.healthComments = '';
			this.siteRiskDetails.healthMitPlan = '';
			this.siteRiskDetails.healthOwner = '';
			this.siteRiskDetails.healthTgtDate = '';
		}
//pro
		if (lable == 'dt2') {
			this.siteRiskDetails.dtcComments = ''
			this.siteRiskDetails.dtcMitPlan = ''
			this.siteRiskDetails.dtcOwner = ''
			this.siteRiskDetails.dtcTgtDate = ''
		}
		if (lable == 'newdesign') {
			this.siteRiskDetails.newDesignComments = ''
			this.siteRiskDetails.newDesignMitPlan = ''
			this.siteRiskDetails.newDesignOwner = ''
			this.siteRiskDetails.newDesignTgtDate = ''
		}
		if (lable == 'gtc') {
			this.siteRiskDetails.gtcComments = ''
			this.siteRiskDetails.gtcMitPlan = ''
			this.siteRiskDetails.gtcOwner = ''
			this.siteRiskDetails.gtcTgtDate = ''
		}
		if (lable == 'planned') {
			this.siteRiskDetails.plannedOwner = ''
			this.siteRiskDetails.plannedMitPlan = ''
			this.siteRiskDetails.plannedTgtDate = ''
			this.siteRiskDetails.plannedComments = ''
		}
	}

	//Program Risk
	overallProgramRisk(value: String, name: String) {
		this.staAlignedLL6Verified();
		if (value == "GREEN") this.proColor = "G";
		else if (value == "YELLOW") this.proColor = "Y";
		else if (value == "RED") this.proColor = "R";
		else if (value == this.NA) this.proColor = "B"
		else this.proColor = this.EMPTY;
		this.setColorToParent = this.proColor;

		if (name == "dt2Mpa") this.dt2Mpa = this.proColor;
		else if (name == "newDsnTech") this.newDsnTech = this.proColor;
		else if (name == "gtcCompl") this.gtcCompl = this.proColor;
		else if (name == "plannesPSW") this.plannesPSW = this.proColor;

		if (this.dt2Mpa != this.EMPTY && this.newDsnTech != this.EMPTY && this.gtcCompl != this.EMPTY && this.plannesPSW != this.EMPTY) {
			if (this.dt2Mpa == 'GREEN') this.dt2Mpa = 'G';
			if (this.dt2Mpa == 'RED') this.dt2Mpa = 'R';
			if (this.dt2Mpa == 'YELLOW') this.dt2Mpa = 'Y';
			if (this.dt2Mpa == this.NA) this.dt2Mpa = 'B';

			if (this.newDsnTech == 'GREEN') this.newDsnTech = 'G';
			if (this.newDsnTech == 'RED') this.newDsnTech = 'R';
			if (this.newDsnTech == 'YELLOW') this.newDsnTech = 'Y';
			if (this.newDsnTech == this.NA) this.newDsnTech = 'B';

			if (this.gtcCompl == 'GREEN') this.gtcCompl = 'G';
			if (this.gtcCompl == 'RED') this.gtcCompl = 'R';
			if (this.gtcCompl == 'YELLOW') this.gtcCompl = 'Y';
			if (this.gtcCompl == this.NA) this.gtcCompl = 'B';

			if (this.plannesPSW == 'GREEN') this.plannesPSW = 'G';
			if (this.plannesPSW == 'RED') this.plannesPSW = 'R';
			if (this.plannesPSW == 'YELLOW') this.plannesPSW = 'Y';
			if (this.plannesPSW == this.NA) this.plannesPSW = 'B';

			this.setColorToParent = this.dt2Mpa + this.newDsnTech + this.gtcCompl + this.plannesPSW;

			let proRedCount = 0, proYellowCount = 0, proGreenCount = 0, proBlueCount = 0;
			for (let i = 0; i < this.setColorToParent.length; i++) {
				if (this.setColorToParent[i] == 'R')
					proRedCount++;
				else if (this.setColorToParent[i] == 'Y')
					proYellowCount++;
				else if (this.setColorToParent[i] == 'B')
					proBlueCount++;
				else
					proGreenCount++;
			}
			if (proRedCount >= 1)
				this.siteRiskDetails.overallParentRisk = "RED";
			else if (proBlueCount >= 1)
				this.siteRiskDetails.overallParentRisk = "BLUE";
			else if (proYellowCount >= 1)
				this.siteRiskDetails.overallParentRisk = "YELLOW";
			else if (proGreenCount >= 1)
				this.siteRiskDetails.overallParentRisk = "GREEN";
			else
				this.siteRiskDetails.overallParentRisk = this.EMPTY;
		} else {
			this.setColorToParent = this.EMPTY
			this.siteRiskDetails.overallParentRisk = this.EMPTY;
		}
	}

	reset() {
		this.isMandatory = false;
		this.capComments = "";
		this.capMitPlan = "";
		this.supComments = "";
		this.supMitPlan = "";
		this.proComments = "";
		this.proMitPlan = "";
		this.qltyComments = "";
		this.qltyMitPlan = "";
	}

	resetQualityCommentsAndMitPlan() {
		this.siteRiskDetails.q1ScoreComments = '';
		this.siteRiskDetails.q1ScoreMitPlan = '';
		this.siteRiskDetails.q1ScoreOwner = '';
		this.siteRiskDetails.q1ScoreTgtDate = '';
		this.siteRiskDetails.q1StatusComments = '';
		this.siteRiskDetails.q1StatusMitPlan = '';
		this.siteRiskDetails.q1StatusOwner = '';
		this.siteRiskDetails.q1StatusTgtDate = '';
		this.siteRiskDetails.ppmComments = '';
		this.siteRiskDetails.ppmMitPlan = '';
		this.siteRiskDetails.ppmOwner = '';
		this.siteRiskDetails.ppmTgtDate = '';
		this.siteRiskDetails.warrantyComments = '';
		this.siteRiskDetails.warrantyMitPlan = '';
		this.siteRiskDetails.warrantyOwner = '';
		this.siteRiskDetails.warrantyTgtDate = '';
		this.siteRiskDetails.stopShipComments = '';
		this.siteRiskDetails.stopShipMitPlan = '';
		this.siteRiskDetails.stopShipOwner = '';
		this.siteRiskDetails.stopShipTgtDate = '';
		this.siteRiskDetails.fsaComments = '';
		this.siteRiskDetails.fsaMitPlan = '';
		this.siteRiskDetails.fsaOwner = '';
		this.siteRiskDetails.fsaTgtDate = '';
		this.siteRiskDetails.deliveryComments = '';
		this.siteRiskDetails.deliveryMitPlan = '';
		this.siteRiskDetails.deliveryOwner = '';
		this.siteRiskDetails.deliveryTgtDate = '';
		this.siteRiskDetails.priorLaunchComments = '';
		this.siteRiskDetails.priorLaunchMitPlan = '';
		this.siteRiskDetails.priorLaunchOwner = '';
		this.siteRiskDetails.priorLaunchTgtDate = '';
		this.siteRiskDetails.q1MSAComments = '';
		this.siteRiskDetails.q1MSAMitPlan = '';
		this.siteRiskDetails.q1MSAOwner = '';
		this.siteRiskDetails.q1MSATgtDate = '';
	}

	mandatoryFieldsCheck() {
		if (this.staVerified && this.isSTA) {

			//Q
			if ((this.siteRiskDetails.q1ScoreColor == 'R' && (this.siteRiskDetails.q1ScoreMitPlan == '' || this.siteRiskDetails.q1ScoreOwner == '' || this.siteRiskDetails.q1ScoreTgtDate == null)) ||
				(this.siteRiskDetails.q1CodeColor == 'R' && (this.siteRiskDetails.q1StatusMitPlan == '' || this.siteRiskDetails.q1StatusOwner == '' || this.siteRiskDetails.q1StatusTgtDate == null)) ||
				(this.siteRiskDetails.ppmColor == 'R' && (this.siteRiskDetails.ppmMitPlan == '' || this.siteRiskDetails.ppmOwner == '' || this.siteRiskDetails.ppmTgtDate == null)) ||
				(this.siteRiskDetails.openwrntySpikesColor == 'R' && (this.siteRiskDetails.warrantyMitPlan == '' || this.siteRiskDetails.warrantyOwner == '' || this.siteRiskDetails.warrantyTgtDate == null)) ||
				(this.siteRiskDetails.stopshipmentsColor == 'R' && (this.siteRiskDetails.stopShipMitPlan == '' || this.siteRiskDetails.stopShipOwner == '' || this.siteRiskDetails.stopShipTgtDate == null)) ||
				(this.siteRiskDetails.openFSAColor == 'R' && (this.siteRiskDetails.fsaMitPlan == '' || this.siteRiskDetails.fsaOwner == '' || this.siteRiskDetails.fsaTgtDate == null)) ||
				(this.siteRiskDetails.dlySixMonthsAvgColor == 'R' && (this.siteRiskDetails.deliveryMitPlan == '' || this.siteRiskDetails.deliveryOwner == '' || this.siteRiskDetails.deliveryTgtDate == null)) ||
				(this.siteRiskDetails.priorLaunchPrfColor == 'R' && (this.siteRiskDetails.priorLaunchMitPlan == '' || this.siteRiskDetails.priorLaunchOwner == '' || this.siteRiskDetails.priorLaunchTgtDate == null)) ||
				(this.siteRiskDetails.qltySysQ1MSAColor == 'R' && (this.siteRiskDetails.q1MSAMitPlan == '' || this.siteRiskDetails.q1MSAOwner == '' || this.siteRiskDetails.q1MSATgtDate == null))) {
				this.qltyMitPlan = this.STA_RED_STATUS;
				this.isMandatory = true;
			}
			if (((this.siteRiskDetails.q1ScoreColor == 'Y' || this.siteRiskDetails.q1ScoreColor == 'B') && (this.siteRiskDetails.q1ScoreComments == '' || this.siteRiskDetails.q1ScoreComments == null)) ||
				((this.siteRiskDetails.q1CodeColor == 'Y' || this.siteRiskDetails.q1CodeColor == 'B') && (this.siteRiskDetails.q1StatusComments == '' || this.siteRiskDetails.q1StatusComments == null)) ||
				((this.siteRiskDetails.ppmColor == 'Y' || this.siteRiskDetails.ppmColor == 'B') && (this.siteRiskDetails.ppmComments == '' || this.siteRiskDetails.ppmComments == null)) ||
				((this.siteRiskDetails.openwrntySpikesColor == 'Y' || this.siteRiskDetails.openwrntySpikesColor == 'B') && (this.siteRiskDetails.warrantyComments == '' || this.siteRiskDetails.warrantyComments == null)) ||
				((this.siteRiskDetails.stopshipmentsColor == 'Y' || this.siteRiskDetails.stopshipmentsColor == 'B') && (this.siteRiskDetails.stopShipComments == '' || this.siteRiskDetails.stopShipComments == null)) ||
				((this.siteRiskDetails.openFSAColor == 'Y' || this.siteRiskDetails.openFSAColor == 'B') && (this.siteRiskDetails.fsaComments == '' || this.siteRiskDetails.fsaComments == null)) ||
				((this.siteRiskDetails.dlySixMonthsAvgColor == 'Y' || this.siteRiskDetails.dlySixMonthsAvgColor == 'B') && (this.siteRiskDetails.deliveryComments == '' || this.siteRiskDetails.deliveryComments == null)) ||
				((this.siteRiskDetails.priorLaunchPrfColor == 'Y' || this.siteRiskDetails.priorLaunchPrfColor == 'B') && (this.siteRiskDetails.priorLaunchComments == '' || this.siteRiskDetails.priorLaunchComments == null)) ||
				((this.siteRiskDetails.qltySysQ1MSAColor == 'Y' || this.siteRiskDetails.qltySysQ1MSAColor == 'B') && (this.siteRiskDetails.q1MSAComments == '' || this.siteRiskDetails.q1MSAComments == null))) {
				this.qltyComments = this.STA_Y_B_STATUS;
				this.isMandatory = true;
			}
			// C
			if (((this.siteRiskDetails.sharedCpty == 'YELLOW' || this.siteRiskDetails.sharedCpty == 'Not Available') && (this.siteRiskDetails.sharedCapComments == '' || this.siteRiskDetails.sharedCapComments == null)) ||
				((this.siteRiskDetails.localLabor == 'YELLOW' || this.siteRiskDetails.localLabor == 'Not Available') && (this.siteRiskDetails.localLaborComments == '' || this.siteRiskDetails.localLaborComments == null)) ||
				((this.siteRiskDetails.manfReadiness == 'YELLOW' || this.siteRiskDetails.manfReadiness == 'Not Available') && (this.siteRiskDetails.manfCapComments == '' || this.siteRiskDetails.manfCapComments == null))) {
				this.capComments = this.STA_Y_B_STATUS;
				this.isMandatory = true;
			}
			if ((this.siteRiskDetails.sharedCpty == 'RED' && (this.siteRiskDetails.sharedCapMitPlan == '' || this.siteRiskDetails.sharedCapOwner == '' || this.siteRiskDetails.sharedCapTgtDate == null)) ||
				(this.siteRiskDetails.localLabor == 'RED' && (this.siteRiskDetails.localLaborMitPlan == '' || this.siteRiskDetails.localLaborOwner == '' || this.siteRiskDetails.localLaborTgtDate == null)) ||
				(this.siteRiskDetails.manfReadiness == 'RED' && (this.siteRiskDetails.manfCapMitPlan == '' || this.siteRiskDetails.manfCapOwner == '' || this.siteRiskDetails.manfCapTgtDate == null))) {
				this.capMitPlan = this.STA_RED_STATUS;
				this.isMandatory = true;
			}
			//S
			if (((this.siteRiskDetails.techCapability == 'YELLOW' || this.siteRiskDetails.techCapability == 'Not Available') && (this.siteRiskDetails.techCapComments == '' || this.siteRiskDetails.techCapComments == null)) ||
				((this.siteRiskDetails.siteLdrCpty == 'YELLOW' || this.siteRiskDetails.siteLdrCpty == 'Not Available') && (this.siteRiskDetails.siteLeaderComments == '' || this.siteRiskDetails.siteLeaderComments == null)) ||
				((this.siteRiskDetails.localToolCpty == 'YELLOW' || this.siteRiskDetails.localToolCpty == 'Not Available') && (this.siteRiskDetails.toolCapComments == '' || this.siteRiskDetails.toolCapComments == null)) ||
				((this.siteRiskDetails.mulLaunch == 'YELLOW' || this.siteRiskDetails.mulLaunch == 'Not Available') && (this.siteRiskDetails.multiLaunchComments == '' || this.siteRiskDetails.multiLaunchComments == null)) ||
				((this.siteRiskDetails.ilvsExp == 'YELLOW' || this.siteRiskDetails.ilvsExp == 'Not Available') && (this.siteRiskDetails.ilvsComments == '' || this.siteRiskDetails.ilvsComments == null)) ||
				((this.siteRiskDetails.subtierMgmt == 'YELLOW' || this.siteRiskDetails.subtierMgmt == 'Not Available') && (this.siteRiskDetails.subtierComments == '' || this.siteRiskDetails.subtierComments == null)) ||
				((this.siteRiskDetails.healthChartColor == 'YELLOW' || this.siteRiskDetails.healthChartColor == 'Not Available') && (this.siteRiskDetails.healthComments == '' || this.siteRiskDetails.healthComments == null))) {
				this.supComments = this.STA_Y_B_STATUS;
				this.isMandatory = true;
			}
			if ((this.siteRiskDetails.techCapability == 'RED' && (this.siteRiskDetails.techMitPlan == '' || this.siteRiskDetails.techOwner == '' || this.siteRiskDetails.techTgtDate == null)) ||
				(this.siteRiskDetails.siteLdrCpty == 'RED' && (this.siteRiskDetails.siteLeaderMitPlan == '' || this.siteRiskDetails.siteLeaderOwner == '' || this.siteRiskDetails.siteLeaderTgtDate == null)) ||
				(this.siteRiskDetails.localToolCpty == 'RED' && (this.siteRiskDetails.toolCapMitPlan == '' || this.siteRiskDetails.toolCapOwner == '' || this.siteRiskDetails.toolCapTgtDate == null)) ||
				(this.siteRiskDetails.mulLaunch == 'RED' && (this.siteRiskDetails.multiLaunchMitPlan == '' || this.siteRiskDetails.multiLaunchOwner == '' || this.siteRiskDetails.multiLaunchTgtDate == null)) ||
				(this.siteRiskDetails.subtierMgmt == 'RED' && (this.siteRiskDetails.subtierMitPlan == '' || this.siteRiskDetails.subtierOwner == '' || this.siteRiskDetails.subtierTgtDate == null)) ||
				(this.siteRiskDetails.ll4Site == 'RED' && (this.siteRiskDetails.ll4SiteMitPlan == '' || this.siteRiskDetails.ll4SiteOwner == '' || this.siteRiskDetails.ll4SiteTgtDate == null)) ||
				(this.siteRiskDetails.healthChartColor == 'RED' && (this.siteRiskDetails.healthMitPlan == '' || this.siteRiskDetails.healthOwner == '' || this.siteRiskDetails.healthTgtDate == null))) {
				this.supMitPlan = this.STA_RED_STATUS;
				this.isMandatory = true;
			}
			//P
			if ((this.siteRiskDetails.plannesPSW == 'YELLOW' || this.siteRiskDetails.plannesPSW == 'Not Available') && (this.siteRiskDetails.plannedComments == '' || this.siteRiskDetails.plannedComments == null)) {
				this.proComments = this.STA_Y_B_STATUS;
				this.isMandatory = true;
			}
			if (this.siteRiskDetails.plannesPSW == 'RED' && (this.siteRiskDetails.plannedMitPlan == '' || this.siteRiskDetails.plannedOwner == '' || this.siteRiskDetails.plannedTgtDate == null)) {
				this.proMitPlan = this.STA_RED_STATUS;
				this.isMandatory = true;
			}
		} else if (this.isBuyer) {
			//C
			if ((this.siteRiskDetails.opePattern == 'YELLOW' || this.siteRiskDetails.opePattern == 'Not Available') && (this.siteRiskDetails.opePatComments == '' || this.siteRiskDetails.opePatComments == null)) {
				this.isMandatory = true;
			}
			if (this.siteRiskDetails.opePattern == 'RED' && (this.siteRiskDetails.opePatMitPlan == '' || this.siteRiskDetails.opePatOwner == '' || this.siteRiskDetails.opePatTgtDate == null)) {
				this.isMandatory = true;
			}
			//S
			if (((this.siteRiskDetails.ownership == 'YELLOW' || this.siteRiskDetails.ownership == 'Not Available') && (this.siteRiskDetails.ownershipComments == '' || this.siteRiskDetails.ownershipComments == null)) ||
				((this.siteRiskDetails.finRisk == 'YELLOW' || this.siteRiskDetails.finRisk == 'Not Available') && (this.siteRiskDetails.finRiskComments == '' || this.siteRiskDetails.finRiskComments == null)) ||
				((this.siteRiskDetails.ll4Site == 'YELLOW' || this.siteRiskDetails.ll4Site == 'Not Available') && (this.siteRiskDetails.ll4SiteComments == '' || this.siteRiskDetails.ll4SiteComments == null))) {
				this.isMandatory = true;
			}
			if ((this.siteRiskDetails.ownership == 'RED' && (this.siteRiskDetails.ownershipMitPlan == '' || this.siteRiskDetails.ownershipOwner == '' || this.siteRiskDetails.ownershipTgtDate == null)) ||
				(this.siteRiskDetails.finRisk == 'RED' && (this.siteRiskDetails.finRiskMitPlan == '' || this.siteRiskDetails.finRiskOwner == '' || this.siteRiskDetails.finRiskTgtDate == null)) ||
				(this.siteRiskDetails.ilvsExp == 'RED' && (this.siteRiskDetails.ilvsMitPlan == '' || this.siteRiskDetails.ilvsOwner == '' || this.siteRiskDetails.ilvsTgtDate == null))) {
				this.isMandatory = true;
			}
			//P
			if (((this.siteRiskDetails.gtcCompl == 'YELLOW' || this.siteRiskDetails.gtcCompl == 'Not Available') && (this.siteRiskDetails.gtcComments == '' || this.siteRiskDetails.gtcComments == null)) ||
				((this.siteRiskDetails.newDsnTech == 'YELLOW' || this.siteRiskDetails.newDsnTech == 'Not Available') && (this.siteRiskDetails.newDesignComments == '' || this.siteRiskDetails.newDesignComments == null)) ||
				((this.siteRiskDetails.dt2Mpa == 'YELLOW' || this.siteRiskDetails.dt2Mpa == 'Not Available') && (this.siteRiskDetails.dtcComments == '' || this.siteRiskDetails.dtcComments == null))) {
				this.isMandatory = true;
			}
			if ((this.siteRiskDetails.dt2Mpa === 'RED' && (this.siteRiskDetails.dtcMitPlan == '' || this.siteRiskDetails.dtcOwner == '' || this.siteRiskDetails.dtcTgtDate == null)) ||
				(this.siteRiskDetails.gtcCompl === 'RED' && (this.siteRiskDetails.gtcMitPlan == '' || this.siteRiskDetails.gtcOwner == '' || this.siteRiskDetails.gtcTgtDate == null)) ||
				(this.siteRiskDetails.newDsnTech === 'RED' && (this.siteRiskDetails.newDesignMitPlan == '' || this.siteRiskDetails.newDesignOwner == '' || this.siteRiskDetails.newDesignTgtDate == null))) {
				this.isMandatory = true;
			}
		}
	}

	viewPartDetails(psa: string) {
		this.siteService.getPartDetails(psa).subscribe(parts => {
			if(parts.status !== null){
				this.partStatus = parts.status;
			}else{
				this.partStatus == null;
				this.partDetails = new Array(parts.swtParts.length);
				for (let i = 0; i < parts.swtParts.length; i++) {
					this.partDetails[i] = new GetPartData(parts.swtParts[i]);
				}
			}
		});
	}

	leadSTAId = false;
	reAssignNewBuyer = false;
	//P-Risk
	dt2OwnerID = false;
	newDesignOwnerID = false;
	gtcOwnerID = false;
	plannedOwnerID = false;

	//S-Risk
	technicalOwnerID = false;
	siteOwnerID = false;
	financialOwnerID = false;
	ownershipOwnerID = false;
	toolOwnerID = false;
	multipleOwnerID = false;
	iLVSOwnerID = false;
	subTierOwnerID = false;
	level4OwnerID = false;
	healthOwnerID = false;

	//C-Risk
	sharedOwnerID = false;
	localOwnerID = false;
	operatingOwnerID = false;
	manufactureOwnerID = false;

	//Q-Risk
	q1ScoreOwnerID = false;
	q1StatusOwnerID = false;
	ppmOwnerID = false;
	warrantyOwnerID = false;
	ssOwnerID = false;
	fsaOwnerID = false;
	deliveryOwnerID = false;
	priorOwnerID = false;
	q1MSAOwnerID = false;

	filteredCDSIDs: any[];
	private cdsLookUpResponse: CDSLookUpResponse[];

	searchKeywordForCDSId(event, name: string) {
		let filtered: any[] = [];
		this.cdsLookUpResponse = [];
		const userCDSId = event.query;
		if (userCDSId.length > 2) {
			this.noMatchFoundValid(name, false);
			this.siteService.getCDSIdDetails(userCDSId).subscribe(cdsIDs => {
					this.cdsLookUpResponse = cdsIDs;
					if (this.cdsLookUpResponse !== null) {
						for (let i = 0; i < this.cdsLookUpResponse.length; i++) {
							//let cdsId = this.cdsLookUpResponse[i].fullName;
							let cdsId = this.cdsLookUpResponse[i].userId+ '-' +this.cdsLookUpResponse[i].fordDisplayName+ '-' +this.cdsLookUpResponse[i].fordDesignation;
							filtered.push(cdsId);
						}
						this.filteredCDSIDs = filtered;
						filtered = [];
					} else {
						this.noMatchFoundValid(name, true);
						this.filteredCDSIDs = [];
					}
				}, error => {
					console.log(error.status);
					this.noMatchFoundValid(name, true);
				}
			);
		} else {
			this.noMatchFoundValid(name, false);
			this.filteredCDSIDs = [];
			this.cdsLookUpResponse = [];
		}
	}

	getCDSId(value: string) {
		if (value == 'leadSTA'){
			this.siteRiskDetails.staEng = this.siteRiskDetails.staEng.split('-')[0].trim();
			if(this.siteRiskDetails.staEng != this.staEngFromDB){
				this.staVerified = false;
				this.staAligned = '';
				this.siteRiskDetails.staAligned = '';
				this.siteRiskDetails.currentDate = '';
				this.siteRiskDetails.ll6Verified = 'N';
			} else{
				//this.staAligned = 'Y';
				this.siteRiskDetails.staAligned='Y';
				this.staVerified = true;
				this.siteRiskDetails.ll6Verified = 'Y';
				this.siteRiskDetails.currentDate =this.timeStampFromDB;
			}
		}else if (value == 'newSiteSTA'){
			this.reassignSTAEng = this.reassignSTAEng.split('-')[0].trim();
		}else if (value == 'reAssignBuyer'){
			this.reassignBuyer = this.reassignBuyer.split('-')[0].trim();
		}else if (value == 'dt2')
			this.siteRiskDetails.dtcOwner = this.siteRiskDetails.dtcOwner.split('-')[0].trim();
		else if (value == 'newDesign')
			this.siteRiskDetails.newDesignOwner = this.siteRiskDetails.newDesignOwner.split('-')[0].trim();
		else if (value == 'gtc')
			this.siteRiskDetails.gtcOwner = this.siteRiskDetails.gtcOwner.split('-')[0].trim();
		else if (value == 'planned')
			this.siteRiskDetails.plannedOwner = this.siteRiskDetails.plannedOwner.split('-')[0].trim();

		//S-Risk
		else if (value == 'technical')
			this.siteRiskDetails.techOwner = this.siteRiskDetails.techOwner.split('-')[0].trim();
		else if (value == 'siteLeader')
			this.siteRiskDetails.siteLeaderOwner = this.siteRiskDetails.siteLeaderOwner.split('-')[0].trim();
		else if (value == 'finRisk')
			this.siteRiskDetails.finRiskOwner = this.siteRiskDetails.finRiskOwner.split('-')[0].trim();
		else if (value == 'ownership')
			this.siteRiskDetails.ownershipOwner = this.siteRiskDetails.ownershipOwner.split('-')[0].trim();
		else if (value == 'toolCapability')
			this.siteRiskDetails.toolCapOwner = this.siteRiskDetails.toolCapOwner.split('-')[0].trim();
		else if (value == 'multiLaunch')
			this.siteRiskDetails.multiLaunchOwner = this.siteRiskDetails.multiLaunchOwner.split('-')[0].trim();
		else if (value == 'iLVS')
			this.siteRiskDetails.ilvsOwner = this.siteRiskDetails.ilvsOwner.split('-')[0].trim();
		else if (value == 'subTier')
			this.siteRiskDetails.subtierOwner = this.siteRiskDetails.subtierOwner.split('-')[0].trim();
		else if (value == 'll4Site')
			this.siteRiskDetails.ll4SiteOwner = this.siteRiskDetails.ll4SiteOwner.split('-')[0].trim();
		else if (value == 'healthChart')
			this.siteRiskDetails.healthOwner = this.siteRiskDetails.healthOwner.split('-')[0].trim();

		//C-Risk
		else if (value == 'shared')
			this.siteRiskDetails.sharedCapOwner = this.siteRiskDetails.sharedCapOwner.split('-')[0].trim();
		else if (value == 'local')
			this.siteRiskDetails.localLaborOwner = this.siteRiskDetails.localLaborOwner.split('-')[0].trim();
		else if (value == 'operating') {
			this.siteRiskDetails.opePatOwner = this.siteRiskDetails.opePatOwner.split('-')[0].trim();
		}

		else if (value == 'manufacture')
			this.siteRiskDetails.manfCapOwner = this.siteRiskDetails.manfCapOwner.split('-')[0].trim();

		//Q-Risk
		else if (value == 'q1Score')
			this.siteRiskDetails.q1ScoreOwner = this.siteRiskDetails.q1ScoreOwner.split('-')[0].trim();
		else if (value == 'q1Status')
			this.siteRiskDetails.q1StatusOwner = this.siteRiskDetails.q1StatusOwner.split('-')[0].trim();
		else if (value == 'ppm')
			this.siteRiskDetails.ppmOwner = this.siteRiskDetails.ppmOwner.split('-')[0].trim();
		else if (value == 'warranty')
			this.siteRiskDetails.warrantyOwner = this.siteRiskDetails.warrantyOwner.split('-')[0].trim();
		else if (value == 'stopShip')
			this.siteRiskDetails.stopShipOwner = this.siteRiskDetails.stopShipOwner.split('-')[0].trim();
		else if (value == 'fsa')
			this.siteRiskDetails.fsaOwner = this.siteRiskDetails.fsaOwner.split('-')[0].trim();
		else if (value == 'delivery')
			this.siteRiskDetails.deliveryOwner = this.siteRiskDetails.deliveryOwner.split('-')[0].trim();
		else if (value == 'prior')
			this.siteRiskDetails.priorLaunchOwner = this.siteRiskDetails.priorLaunchOwner.split('-')[0].trim();
		else if (value == 'q1MSA')
			this.siteRiskDetails.q1MSAOwner = this.siteRiskDetails.q1MSAOwner.split('-')[0].trim();
	}

	noMatchFoundValid(value: string, checkValid) {
		//lead STA
		if(value == 'leadSTA'){
			this.leadSTAId = checkValid;
		}
		if(value == 'reAssignBuyer'){
			this.reAssignNewBuyer = checkValid;
		}

		//P-Risk
		if (value == 'dt2')
			this.dt2OwnerID = checkValid;
		else if (value == 'newDesign')
			this.newDesignOwnerID = checkValid;
		else if (value == 'gtc')
			this.gtcOwnerID = checkValid;
		else if (value == 'planned')
			this.plannedOwnerID = checkValid;

		//S-Risk
		else if (value == 'technical')
			this.technicalOwnerID = checkValid;
		else if (value == 'siteLeader')
			this.siteOwnerID = checkValid;
		else if (value == 'finRisk') {
			this.financialOwnerID = checkValid;
		} else if (value == 'ownership')
			this.ownershipOwnerID = checkValid;
		else if (value == 'toolCapability')
			this.toolOwnerID = checkValid;
		else if (value == 'multiLaunch')
			this.multipleOwnerID = checkValid;
		else if (value == 'iLVS')
			this.iLVSOwnerID = checkValid;
		else if (value == 'subTier')
			this.subTierOwnerID = checkValid;
		else if (value == 'll4Site')
			this.level4OwnerID = checkValid;
		else if (value == 'healthChart')
			this.healthOwnerID = checkValid;

		//C-Risk
		else if (value == 'shared')
			this.sharedOwnerID = checkValid;
		else if (value == 'local')
			this.localOwnerID = checkValid;
		else if (value == 'operating')
			this.operatingOwnerID = checkValid;
		else if (value == 'manufacture')
			this.manufactureOwnerID = checkValid;

		//Q-Risk
		else if (value == 'q1Score')
			this.q1ScoreOwnerID = checkValid;
		else if (value == 'q1Status')
			this.q1StatusOwnerID = checkValid;
		else if (value == 'ppm')
			this.ppmOwnerID = checkValid;
		else if (value == 'warranty')
			this.warrantyOwnerID = checkValid;
		else if (value == 'stopShip')
			this.ssOwnerID = checkValid;
		else if (value == 'fsa')
			this.fsaOwnerID = checkValid;
		else if (value == 'delivery')
			this.deliveryOwnerID = checkValid;
		else if (value == 'prior')
			this.priorOwnerID = checkValid;
		else if (value == 'q1MSA')
			this.q1MSAOwnerID = checkValid;
	}

	onReassign() {
		this.reassign = false;
		this.displayModal = true;
		this.displayModalReassign=false;
		this.reassignBuyer = null;
		this.errorMsgBuyer = null;
	}

	onSiteCodeReassign() {
		if((this.leadSTAId) ||	(this.siteRiskDetails.staEng == null || this.siteRiskDetails.staEng =='')){
			this.utilService.pushMsg('error', 'Error', 'Please enter a valid Lead STA (Site/Core) CDSID.');
		}
		else if((this.siteRiskDetails.notifyUsersFlag) && (this.siteRiskDetails.notifyAdditionalUsers == null ||
			this.siteRiskDetails.notifyAdditionalUsers == "")) {
			this.utilService.pushMsg('error', 'Error', 'Please enter valid CDSIDs to Notify Additional Users.');
		}
		else{
			this.siteReassign = false;
			this.displayModalReassign = true;
			this.isSimSite = 'yes';
			this.reassignSiteCode = null;
		}
	}

	reassignYes() {
		this.reassign = true;
	}

	reassignKeepClear(value:string) {
		this.keepClear = value;
		this.siteReassign = true;
		this.isSimSite = 'no';
		this.simAndGsdb='sim';
	}

	reassignToNewSiteCode(psaNumber:string,value:string):void{
		//alert("reassignToNewSiteCode calling isCurrentSiteInSIM---------"+this.isCurrentSiteInSIM)
		if (this.reassignSiteCode.length <= 4 ) {
			this.utilService.pushMsg('error', 'Error', 'Site Code length should be 5 characters.');
		} else if (this.reassignSiteCode.length >= 5 &&  !this.reassignSiteCode.match('^[A-Z0-9]+$')) {
			this.utilService.pushMsg('error', 'Error', 'Site Code allows only alphanumeric values.');
		}else {
			this.updateSRARequest.isRecordAvailable(this.reassignSiteCode, psaNumber).subscribe(data => {
				if (data !== null) {
					if (this.reassignSiteCode === data.siteCode && psaNumber === data.psaNumber) {
						this.utilService.pushMsg('warn', 'Warning', 'eSRA for this Supplier/PSA combination already exists.');
					}
				} else {
					if(this.simAndGsdb == "gsdb"){
						this.globalSiteCheck();
						this.updateSimOrGsdb("N");
					}
					else {
						this.siteService.getSIMSiteDetails(this.reassignSiteCode).subscribe(data => {
							if (data == null || data === '') {
								this.isSimSite = "gsdb";
								this.simAndGsdb = "gsdb";
							} else {
								this.siteRiskDetails.reAssignSiteData = data;
								this.updateSimOrGsdb("Y");
								this.simAndGsdb = "sim";
							}
						}, error => {
							console.log(error.status);
							this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com' +
								' with a screenshot of this error message.');
						});
					}
				}
			});
		}
	}

   	updateSimOrGsdb(isReassignSiteInSIM:string){
		if((this.siteRiskDetails.reAssignSiteData.staEng == null || this.siteRiskDetails.reAssignSiteData.staEng =='') && this.reassignSTAEng == null){
			this.utilService.pushMsg('warn', 'Information', 'Please enter the CDSID of the STA responsible for assessing the new site.');
			this.newSiteLeadSTA = true;
		} else {
			this.newSiteLeadSTA = false;
			if(this.reassignSTAEng !=null){
				this.siteRiskDetails.reAssignSiteData.staEng = this.reassignSTAEng;
			}
			this.updateSRARequest.updateReassignSite(this.siteRiskDetails, this.reassignSiteCode, this.keepClear,this.isCurrentSiteInSIM,isReassignSiteInSIM).subscribe(data => {
					this.utilService.pushMsg('success', 'Success', 'Site Code has been re-assigned successfully.');
					this.router.navigate(['home']);
			}, error => {
				if (error.status === 406) {
					this.displayModalReassign = false;
					this.utilService.pushMsg('error', 'Error', 'Enter Valid CDSID(s) for Notified Additional Users.');
				}
			});
		}
	}
simAndGsdb:string;
	globalSiteCheck() {
		this.siteService.getGSDBSiteDetails(this.reassignSiteCode).subscribe(globalSiteData => {
			this.confirmation='';
			this.isSimSite = 'no';
			this.newSiteLeadSTA=true;
			if (globalSiteData == null) {
				this.utilService.pushMsg('error', 'Error', 'Enter Valid Manufacturing Site Code.');
			} else{
				/*for (const key in globalSiteData) {
					if (Object.prototype.hasOwnProperty.call(globalSiteData, key)) {
						const value = globalSiteData[key];
						console.log(key + ': ' + value);
					}
				}*/
				this.siteRiskDetails.reAssignSiteData = globalSiteData;
			}
		},
		error => {
			console.log(error.status);
			this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com' +
				' with a screenshot of this error message.');
		});
	}

	reassignToNewBuyer() {
		this.updateSRARequest.reassignBuyer(this.reassignBuyer).subscribe(reassignBuyer => {
			if (reassignBuyer !== null) {
				this.displayModal = false;
				this.siteRiskDetails.buyer = this.reassignBuyer;
				this.updateSRARequest.updateReassignBuyer(this.siteRiskDetails, this.userID).subscribe(updateReassignedBuyer => {
					if (updateReassignedBuyer == 1) {
						this.utilService.pushMsg('success', 'Success', 'Buyer has been re-assigned successfully.');
						this.reassignBuyer = null;
						this.router.navigate(['home']);
					}
				});
			} else {
				this.displayModal = true;
				this.errorMsgBuyer = "Enter Valid Buyer CDS ID"
			}
		}, error => {
			console.log(error.status);
			this.utilService.pushMsg('error', 'Error', 'Unable to reassign.');
		});
	}
	setColorNames() {
		if (this.siteRiskDetails.q1CodeColor == null)
			this.siteRiskDetails.q1CodeColor = this.SELECT_COLOR;
		else {
			this.q1Status = this.getColorNames(this.siteRiskDetails.q1CodeColor);
			this.siteRiskDetails.q1CodeColor = this.q1Status;
		}

		if (this.siteRiskDetails.ppmColor == null)
			this.siteRiskDetails.ppmColor = this.SELECT_COLOR;
		else {
			this.ppm = this.getColorNames(this.siteRiskDetails.ppmColor);
			this.siteRiskDetails.ppmColor = this.ppm;
		}

		if (this.siteRiskDetails.openwrntySpikesColor == null)
			this.siteRiskDetails.openwrntySpikesColor = this.SELECT_COLOR;
		else {
			this.warranty = this.getColorNames(this.siteRiskDetails.openwrntySpikesColor);
			this.siteRiskDetails.openwrntySpikesColor = this.warranty;
		}

		if (this.siteRiskDetails.stopshipmentsColor == null)
			this.siteRiskDetails.stopshipmentsColor = this.SELECT_COLOR;
		else{
			this.stopship = this.getColorNames(this.siteRiskDetails.stopshipmentsColor);
			this.siteRiskDetails.stopshipmentsColor = this.stopship;
			}

		if (this.siteRiskDetails.openFSAColor == null)
			this.siteRiskDetails.openFSAColor = this.SELECT_COLOR;
		else {
			this.fsa = this.getColorNames(this.siteRiskDetails.openFSAColor);
			this.siteRiskDetails.openFSAColor = this.fsa;
		}

		if (this.siteRiskDetails.dlySixMonthsAvgColor == null)
			this.siteRiskDetails.dlySixMonthsAvgColor = this.SELECT_COLOR;
		else {
			this.delivery = this.getColorNames(this.siteRiskDetails.dlySixMonthsAvgColor);
			this.siteRiskDetails.dlySixMonthsAvgColor = this.delivery;
		}

		if (this.siteRiskDetails.priorLaunchPrfColor == null)
			this.siteRiskDetails.priorLaunchPrfColor = this.SELECT_COLOR;
		else {
			this.priorlaunch = this.getColorNames(this.siteRiskDetails.priorLaunchPrfColor);
			this.siteRiskDetails.priorLaunchPrfColor = this.priorlaunch;
		}

		if (this.siteRiskDetails.qltySysQ1MSAColor == null)
			this.siteRiskDetails.qltySysQ1MSAColor = this.SELECT_COLOR;
		else {
			this.q1msa = this.getColorNames(this.siteRiskDetails.qltySysQ1MSAColor);
			this.siteRiskDetails.qltySysQ1MSAColor = this.q1msa;
		}
	}

	getColorNames(colorCode:string) {
		if (colorCode == 'B')
			colorCode = "BLUE"
		else if (colorCode == 'R')
			colorCode = "RED"
		else if (colorCode == 'Y')
			colorCode = "YELLOW"
		else if (colorCode == 'G')
			colorCode = "GREEN"
		return colorCode;
	}

	convertDate(longDate) {
		const radix = 10;
		const date = new Date(parseInt(longDate, radix));
		return this.generateUTCDate(date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear());
	}

	private generateUTCDate(day, month, year) {
		const actualMonth = Number(month) + 1;
		let monthFormat;
		if (actualMonth != null) {
			if (actualMonth.toString() === '01' || actualMonth.toString() === '1') {
				monthFormat = 'Jan';
			} else if (actualMonth.toString() === '02' || actualMonth.toString() === '2') {
				monthFormat = 'Feb';
			} else if (actualMonth.toString() === '03' || actualMonth.toString() === '3') {
				monthFormat = 'Mar';
			} else if (actualMonth.toString() === '04' || actualMonth.toString() === '4') {
				monthFormat = 'Apr';
			} else if (actualMonth.toString() === '05' || actualMonth.toString() === '5') {
				monthFormat = 'May';
			} else if (actualMonth.toString() === '06' || actualMonth.toString() === '6') {
				monthFormat = 'Jun';
			} else if (actualMonth.toString() === '07' || actualMonth.toString() === '7') {
				monthFormat = 'Jul';
			} else if (actualMonth.toString() === '08' || actualMonth.toString() === '8') {
				monthFormat = 'Aug';
			} else if (actualMonth.toString() === '09' || actualMonth.toString() === '9') {
				monthFormat = 'Sep';
			} else if (actualMonth.toString() === '10') {
				monthFormat = 'Oct';
			} else if (actualMonth.toString() === '11') {
				monthFormat = 'Nov';
			} else if (actualMonth.toString() === '12') {
				monthFormat = 'Dec';
			}
		}
		return `${day.toString()}-${monthFormat.toString()}-${year.toString()}`;
	}
	_NoGSDBCheck() {
		console.log('eSRA Create dropped');
	}
}
